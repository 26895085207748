/**
 * Script adapted from
 * https://github.com/mzuccaroli/angular-google-tag-manager
 */
import { Inject, Injectable, Optional, isDevMode } from '@angular/core';
import { GoogleTagManagerConfig } from './google-tag-manager-config';
import { CheckoutDataLayerPush, DataLayerPush, ProductFieldObject } from './enhanced-ecommerce-types/index';
import { ShipmentType } from 'src/app/models/shipment-type.model';
import { Country } from 'src/app/models/country.model';
import { ShipmentServiceType } from 'src/app/models/shipment-service-type.model';
import { Cart } from 'src/app/models/cart.model';
import { Product } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private isLoaded = false;
  private disableScriptInjection = true;

  /**
   * Eventi Google Analytics Ecommerce
   * 1: 'add_to_cart', 2: 'begin_checkout', 3: 'add_shipping_info', 4: 'add_payment_info', 6: 'purchase'
   */
  static readonly stepEvent: { [id: number]: string; } = { 1: 'add_to_cart', 2: 'begin_checkout', 3: 'add_shipping_info', 4: 'add_payment_info', 6: 'purchase' };

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor(
    @Optional()
    @Inject('googleTagManagerConfig')
    public config: GoogleTagManagerConfig = { id: null },
    @Optional() @Inject('googleTagManagerId') public googleTagManagerId: string,
    @Optional()
    @Inject('googleTagManagerAuth')
    public googleTagManagerAuth: string,
    @Optional()
    @Inject('googleTagManagerPreview')
    public googleTagManagerPreview: string,
    @Optional()
    @Inject('googleTagManagerResourcePath')
    public googleTagManagerResourcePath: string,
    @Optional()
    @Inject('googleTagManagerCSPNonce')
    public googleTagManagerCSPNonce: string
  ) {
    if (this.config == null) {
      this.config = { id: null };
    }

    this.config = {
      ...this.config,
      id: googleTagManagerId || this.config.id,
      gtm_auth: googleTagManagerAuth || this.config.gtm_auth,
      gtm_preview: googleTagManagerPreview || this.config.gtm_preview,
      gtm_resource_path: googleTagManagerResourcePath || this.config.gtm_resource_path
    };
    if (this.config.id == null) {
      throw new Error('Google tag manager ID not provided.');
    }
  }

  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    // TODO comment
    // if (isDevMode()) console.log('GTM pushOnDataLayer');
    dataLayer.push(obj);
    // TODO comment
    // if (isDevMode()) console.log(obj);
  }

  /**
  * Invocazione GTM Enhanced Ecommerce v4
  * checkout 
  * @param formData dati ordine
  * @param stepNum step 1: 'add_to_cart', 2: 'begin_checkout', 3: 'add_shipping_info', 4: 'add_payment_info', 6: 'purchase'
  * @param opt descrizione aggiuntiva
  * @param service tipo di spedizione
  * @param orderId Id ordine
  */
  public pushEcommerceData(formData: { items: ShipmentType[], from: Country, to: Country }, stepNum: number, opt?: string, service?: ShipmentServiceType, orderId?: number) {
    try {
      let gtmEvent = null;
      /** Evento ecommerce */
      let event = GoogleTagManagerService.stepEvent[stepNum];
      // Se l'evento è definito
      if (event) {
        if (formData && formData.items && formData.items.length > 0) {
          /** Nome prodotto */
          let brand = this.calculateBrand(formData.from, formData.to);
          /** Prezzo totale */
          let totalPrice = this.calculateTotalPrice(service);
          /** Prodotti */
          let products: any[] = this.calculateItems(formData.items, formData.from, formData.to, service, totalPrice, brand);
          // Valorizzazione dell'evento
          gtmEvent = {
            'event': event,
            'ecommerce': {
              'currency': 'EUR',
              'value': totalPrice,
              // coupon: "Sconto di benvenuto",
              'items': products
            }
          };
          // Se ho coupon sconti imposto il nome
          if (service && service.coupons && service.coupons.length > 0) {
            // Imposto il nome del coupon
            gtmEvent.ecommerce['coupon'] = service.coupons[0].discount_coupon;
          } 

          if ('add_payment_info' == event) {
            // Aggiunta info pagamento
            if (opt) {
              // Tipo di pagamento
              gtmEvent.ecommerce['payment_type'] = opt;
            }
          } else if ('add_shipping_info' == event) {
            // Aggiunta info spedizione
            if (service && service.name) {
              // Tipo di spedizione
              gtmEvent.ecommerce['shipping_tier'] = service.name;
            } else if (opt) {
              gtmEvent.ecommerce['shipping_tier'] = opt;
            }
          } else if ('purchase' == event) {
            // Aggiunta IVA e id ordine
            /** Calcolo IVA */
            let iva = +((totalPrice / 1.22).toFixed(2));
            // Id ordine
            gtmEvent.ecommerce['transaction_id'] = orderId.toString();
            // IVA
            gtmEvent.ecommerce['tax'] = iva;
          }
          // Invio evento ecommerce
          this.pushEcommerce(gtmEvent);

        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Prezzo della spedizione
   * @param service 
   * @returns prezzo della spedizione, 0 prezzo non definito
   */
  protected calculateTotalPrice(service: ShipmentServiceType): number {
    let totalPrice = 0;
    if (service && service.rate) {
      if (service.coupons && service.coupons.length > 0 && service.rate_discounted) {
        // Prezzo scontato
        totalPrice = +(parseFloat(service.rate_discounted.toString()).toFixed(2));
      } else {
        // Prezzo intero (Arrotonda a 2 cifre decimali (complicato da bug di Chrome))
        totalPrice = +(parseFloat(service.rate.toString()).toFixed(2));
      }
    }
    return totalPrice;
  }
  /**
   * Servizi di spedizione
   * @param items 
   * @param from 
   * @param to 
   * @param service 
   * @returns 
   */
  protected calculateItems(items: ShipmentType[], from: Country, to: Country, service: ShipmentServiceType, totalPriceP?: number, brandP?: string,): any[] {
    let products: any[] = [];
    let brand = brandP ?? this.calculateBrand(from, to);
    let totalPrice = totalPriceP ?? this.calculateTotalPrice(service);
    for (const [index, item] of items.entries()) {
      /** 
       * Evento di sample item
        item_id: "SKU_12345",
        item_name: "Stan and Friends Tee",
        affiliation: "Google Merchandise Store",
        coupon: "Sconto di benvenuto",
        discount: 2.22,
        index: 0,
        item_brand: "Google",
        item_category: "Apparel",
        item_category2: "Adult",
        ...
        item_list_id: "related_products",
        item_list_name: "Related Products",
        item_variant: "green",
        location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
        price: 9.99,
        quantity: 1
      */
      let imballo = '';
      if (item.packaging) {
        if ('lit' == item.packaging) {
          if (item.id === 4) {
            imballo = 'BagInBox'
          } else {
            imballo = 'Latta';
          }
        } else if ('bottle' === item.packaging) {
          imballo = 'Bottiglia';
        }
      }
      let product = {
        'item_name': item.name + (imballo ? ' ' + imballo : '') + ' ' + from.name + ' ' + to.name, // Obbligatorio o item_name o item_id
        'index': index, // Progressivo riga carrello (da 0)
        // 'id': '12345',
        'brand': brand, // Italia, Import, Export
        'item_category': item.name, // Pacco, Busta, Vino, Olio ...
        'item_category2': from.name + ' ' + to.name, // Paese origine Paese destinazione
        'quantity': (item.qta && item.qta > 0) ? item.qta : 1, // Quantità
      }
      if (imballo) {
        product['item_category3'] = imballo; // Latta, BagInBox, Bottiglia
      }

      if (service && service.rate) {
        if (index == 0) {
          product['price'] = totalPrice;
          // In caso di sconto
          if (service.coupons && service.coupons.length > 0) {
            // coupon - nome del coupon
            product['coupon'] = service.coupons[0].discount_coupon;
            // discount - importo dello sconto
            product['discount'] = +(parseFloat((service.rate - service.rate_discounted).toString()).toFixed(2));
          }

        } else {
          product['price'] = 0;
        }
      }
      // Aggiungo l'item all'elenco dei prodotti
      products.push(product);
    }
    return products;
  }

  /**
  * Prodotti ecommerce
  * @param items 
  * @param from 
  * @param to 
  * @param service 
  * @returns 
  */
  protected calculateItemsProducts(items: Product[], from: Country, to: Country, service: ShipmentServiceType, totalPriceP?: number, brandP?: string,): any[] {
    let products: any[] = [];
    let brand = brandP ?? this.calculateBrand(from, to);
    let totalPrice = totalPriceP ?? this.calculateTotalPrice(service);
    for (const [index, item] of items.entries()) {
      /** 
       * Evento di sample item
        item_id: "SKU_12345",
        item_name: "Stan and Friends Tee",
        affiliation: "Google Merchandise Store",
        coupon: "Sconto di benvenuto",
        discount: 2.22,
        index: 0,
        item_brand: "Google",
        item_category: "Apparel",
        item_category2: "Adult",
        ...
        item_list_id: "related_products",
        item_list_name: "Related Products",
        item_variant: "green",
        location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
        price: 9.99,
        quantity: 1
      */
      let imballo = '';
      if (item.packaging) {
        if ('lit' == item.packaging) {
          if (item.id === 4) {
            imballo = 'BagInBox'
          } else {
            imballo = 'Latta';
          }
        } else if ('bottle' === item.packaging) {
          imballo = 'Bottiglia';
        }
      }
      let product = {
        'item_name': item.name, // Obbligatorio o item_name o item_id
        'index': index, // Progressivo riga carrello (da 0)
        // 'id': '12345',
        'brand': brand, // Ecommerce
        'item_category': item.category, // Es Ricariche
        'item_category2': item.warehouse ? 'Prodotti fisici' : 'Prodotti digitali',
        'item_category3': item.subtitle ?? '',
        'quantity': (item.qta && item.qta > 0) ? item.qta : 1, // Quantità
      }
      if (imballo) {
        product['item_category3'] = imballo; // Latta, BagInBox, Bottiglia
      }

      if (service && service.rate) {
        if (index == 0) {
          product['price'] = totalPrice;
          // In caso di sconto
          if (service.coupons && service.coupons.length > 0) {
            // coupon - nome del coupon
            product['coupon'] = service.coupons[0].discount_coupon;
            // discount - importo dello sconto
            product['discount'] = +(parseFloat((service.rate - service.rate_discounted).toString()).toFixed(2));
          }

        } else {
          product['price'] = 0;
        }
      }
      // Aggiungo l'item all'elenco dei prodotti
      products.push(product);
    }
    return products;
  }

  /**
   * Nome prodotto in base alla nazione di partenza e destinazione
   * @param from nazione partenza
   * @param to nazione destinazione
   * @returns Italia, Import, Export, Export estero (non si dovrebbe mai verificare)
   */
  calculateBrand(from: Country, to: Country): string {
    return this.calculateBrandCode(from?.country_code, to?.country_code);
  }

  /**
   * Tipologia di operazione. Spedizioni: Italia, Import, Export, Export estero; Ecommerce
   * @param fromCountry_code codice nazione partenza
   * @param toCountry_code codice nazione destinazione
   * @returns  Italia, Import, Export, Ecommerce, Export estero (non si dovrebbe mai verificare)
   */
  calculateBrandCode(fromCountry_code: string, toCountry_code: string): string {
    let brand = '';
    try {
      if ('IT' == fromCountry_code && 'IT' == toCountry_code) {
        brand = 'Italia';
      } else if (!fromCountry_code || !toCountry_code) {
        brand = 'Ecommerce';
      } else if ('IT' != fromCountry_code) {
        brand = 'Import';
      } else if ('IT' != toCountry_code) {
        brand = 'Export';
      } else {
        // Questo caso non si dovrebbe mai verificare
        brand = 'Export estero';
      }
    } catch (error) {
      console.error(error);
    }
    return brand;
  }

  /**
   * Push Google GA4 evento di acquisto spedizione
   * @param orderId
   * @param items
   * @param from
   * @param to
   * @param service
   */
  public pushEcommercePurchaseShipment(orderId: string, items: ShipmentType[], from?: Country, to?: Country, service?: ShipmentServiceType) {
    // Evento ecommerce GA4
    try {
      /** Nome prodotto */
      let brand = this.calculateBrand(from, to);
      /** Prezzo totale */
      let totalPrice = this.calculateTotalPrice(service);
      /** Iva 22% */
      let iva = +((totalPrice / 1.22).toFixed(2));
      /** Prodotti */
      let products: any[] = this.calculateItems(items, from, to, service, totalPrice, brand);
      // Valorizzazione dell'evento
      let gtmEvent = {
        'event': 'purchase',
        'ecommerce': {
          'currency': 'EUR',
          'transaction_id': orderId, // id ordine
          'value': totalPrice,
          'tax': iva, // Imposte IVA
          // coupon: "Sconto di benvenuto",
          'items': products
        }
      };
      // Se ho coupon sconti imposto il nome
      if (service && service.coupons && service.coupons.length > 0) {
        // Imposto il nome del coupon
        gtmEvent.ecommerce['coupon'] = service.coupons[0].discount_coupon;
      }
      // Invio evento ecommerce di acquisto
      this.pushEcommerce(gtmEvent);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Push Google GA4 evento di acquisto spedizione
   * @param orderId 
   * @param items 
   * @param from 
   * @param to 
   * @param service 
   */
  public pushEcommercePurchaseProduct(orderId: string, items: Product[], from?: Country, to?: Country, service?: ShipmentServiceType) {
    // Evento ecommerce GA4
    try {
      /** Nome prodotto */
      let brand = this.calculateBrand(from, to);
      /** Prezzo totale */
      let totalPrice = this.calculateTotalPrice(service);
      /** Iva 22% */
      let iva = +((totalPrice / 1.22).toFixed(2));
      /** Prodotti */
      let products: any[] = this.calculateItemsProducts(items, from, to, service, totalPrice, brand);
      // Valorizzazione dell'evento
      let gtmEvent = {
        'event': 'purchase',
        'ecommerce': {
          'currency': 'EUR',
          'transaction_id': orderId, // id ordine
          'value': totalPrice,
          'tax': iva, // Imposte IVA
          // coupon: "Sconto di benvenuto",
          'items': products
        }
      };
      // Se ho coupon sconti imposto il nome
      if (service && service.coupons && service.coupons.length > 0) {
        // Imposto il nome del coupon
        gtmEvent.ecommerce['coupon'] = service.coupons[0].discount_coupon;
      }
      // Invio evento ecommerce di acquisto
      this.pushEcommerce(gtmEvent);
    } catch (error) {
      console.error(error);
    }
  }

  /**
    * Invocazione GTM Enhanced Ecommerce v4
    * checkout 
    * @param cart carrello
    * @param stepNum step 1: 'add_to_cart', 2: 'begin_checkout', 3: 'add_shipping_info', 4: 'add_payment_info', 6: 'purchase'
    * @param opt descrizione aggiuntiva
    * @param orderId id ordine
    */
  public pushEcommerceDataCart(cart: Cart, stepNum: number, opt?: string, from?: Country, to?: Country, service?: ShipmentServiceType, orderId?: number) { 
    try {
      let gtmEvent = null;
      /** Evento ecommerce */
      let event = GoogleTagManagerService.stepEvent[stepNum];
      // Se l'evento è definito
      if (event) {
        if (cart && cart.items && cart.items.length > 0) {
          //Nome prodotto
          let brand = this.calculateBrand(from, to);
          //Prezzo totale 
          let totalPrice = this.calculateTotalPrice(service);

          //Prodotti 
          let products: any[] = this.calculateItemsProducts(cart.items, from, to, service, totalPrice, brand);
          // Valorizzazione dell'evento
          gtmEvent = {
            'event': event,
            'ecommerce': {
              'currency': 'EUR',
              'value': totalPrice,
              // coupon: "Sconto di benvenuto",
              'items': products
            }
          };
          // Se ho coupon sconti imposto il nome
          if (service && service.coupons && service.coupons.length > 0) {
            // Imposto il nome del coupon
            gtmEvent.ecommerce['coupon'] = service.coupons[0].discount_coupon;
          }

          if ('add_payment_info' == event) {
            // Aggiunta info pagamento
            if (opt) {
              // Tipo di pagamento
              gtmEvent.ecommerce['payment_type'] = opt;
            }
          } else if ('add_shipping_info' == event) {
            // Aggiunta info spedizione
            if (service && service.name) {
              // Tipo di spedizione
              gtmEvent.ecommerce['shipping_tier'] = service.name;
            } else if (opt) {
              gtmEvent.ecommerce['shipping_tier'] = opt;
            }
          } else if ('purchase' == event) {
            // Aggiunta IVA e id ordine
            //Calcolo IVA
            let iva = +((totalPrice / 1.22).toFixed(2));
            // IVA
            gtmEvent.ecommerce['tax'] = iva;
            if (orderId) {
              // Id ordine
              gtmEvent.ecommerce['transaction_id'] = orderId.toString();
            }
          }
        }
      }
      // Invio evento ecommerce GA4
      this.pushEcommerce(gtmEvent);
    } catch (error) {
      console.error(error);
    }
  }


  /**
   * Invia gli eventi GA di visualizzazione prodotti e aggiunta rimozione dal carrello (senza prezzi)
   * @param eventName "view_item" visualizza prodotti | "add_to_cart" aggiungi prodotto a carrello | "remove_from_cart" rimuovi prodotto dal carrello
   * @param control 
   * @param units 
   * @param selectedShipmentTypes spedizione selezionata
   * @param fromCountry nazione di partenza
   * @param toCountry nazione di destinazione
   */
  pushItems(eventName: "view_item" | "add_to_cart" | "remove_from_cart", items: ShipmentType[], service?: ShipmentServiceType, from?: Country, to?: Country) {
    /** Nome prodotto */
    let brand = this.calculateBrand(from, to);
    /** Prezzo totale */
    let totalPrice = 0;
    /** Prodotti */
    let products: any[] = this.calculateItems(items, from, to, service, totalPrice, brand);
    // Evento GA4 ecommerce
    let gtmEvent = {
      'event': eventName,
      'ecommerce': {
        // coupon: "Sconto di benvenuto",
        'items': products
      }
    };
    // Invio evento ecommerce GA4
    this.pushEcommerce(gtmEvent);
  }

  /**
   * Clear last ecommerce object 
   * and send new ecommerce event
   * @param ecommerceJson ecommerce event
   */
  public pushEcommerce(ecommerceJson: Object): void {
    if (ecommerceJson && ecommerceJson != null) {

      // Clear the previous ecommerce object.
      // come  richiesto in documentazione ???
      
      /*
      console.log('pushEcommerce');
      this.pushTag({ ecommerce: null });
      console.log({ ecommerce: null });
      */
      
      // Push ecommerce data
      console.log('pushEcommerce');
      this.pushTag(ecommerceJson);
      console.log(ecommerceJson);
    }
  }

  /**
   * 
   * @param userid id utente
   */
  public pushUserGa4(userid: string) {
    // https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
    // dataLayer.push({
    //   'user_id': 'USER_ID'
    //   });
    this.pushTag(
      {
        'user_id': '' + userid
      }
    );
  }

  /**
  * Rimozioni informazioni utente 
  * (obbligatoria)
  */
  public clearUserGa4(userid = null) {
    try {
      this.pushTag(
        {
          event: 'logout',
          'user_id': userid
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  public addGtmToDom(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.disableScriptInjection) {
        // console.log("GoogleTagManagerService NOT adding GTM script to page.");
        return resolve(this.isLoaded = true);
      } else {
        if (this.isLoaded) {
          return resolve(this.isLoaded);
        }
        const doc = this.browserGlobals.documentRef();
        this.pushOnDataLayer({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });

        const gtmScript = doc.createElement('script');
        gtmScript.id = 'GTMscript';
        gtmScript.async = true;
        gtmScript.src = this.applyGtmQueryParams(
          this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js'
        );
        gtmScript.addEventListener('load', () => {
          return resolve(this.isLoaded = true);
        });
        gtmScript.addEventListener('error', () => {
          return reject(false);
        });
        if (this.googleTagManagerCSPNonce) {
          gtmScript.setAttribute('nonce', this.googleTagManagerCSPNonce);
        }
        console.log("GoogleTagManagerService adding GTM script to page ");
        doc.head.insertBefore(gtmScript, doc.head.firstChild);
      }
    });
  }

  public pushTag(item: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoaded || !this.disableScriptInjection) {
        this.addGtmToDom().then(() => {
          this.pushOnDataLayer(item);
          return resolve();
        }).catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }

  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter((k) => this.config[k])
        .map((k) => `${k}=${this.config[k]}`)
        .join('&')
    );
  }

}
