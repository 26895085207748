import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { IntersectionObserverService } from 'src/app/services/intersection-observer.service';
import { environment } from 'src/environments/environment';
import { ScrollService } from 'src/app/services/scroll.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { isPlatformBrowser } from '@angular/common';
// TODO preventivo 
// import { RichiediPreventivoService } from 'src/app/services/richiedi-preventivo.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})



export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {
  route: string;
  arrayUrl = [];
  createdPath: string;
  currentDate: Date;
  hidden: boolean = false;
  userSubscription: Subscription;
  userLogged: User;
  env: any=environment;
  footerMenu = null;

  // private scrollSubscription: Subscription;
  // @Input() scrollEvents$: Observable<void>;

  // @ViewChild('app-form-master', { read: ElementRef }) ob: ElementRef;
  // private inter: IntersectionObserverService
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private scrollService: ScrollService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    // TODO preventivo 
    // private richiediPreventivoService: RichiediPreventivoService
  ) {
    this.currentDate = new Date();
    // Nasconde o mostra la sticky bottom bar per la CTA alla spedizione
    this.scrollService.scrollY$.subscribe(scrollY => this.hideShowStickyFooterCta(scrollY));
    // console.log(this.activatedRoute);
    // this.event$ = this.router.events
    //   .subscribe(
    //     (event: NavigationEvent) => {
    //
    //       if (event instanceof NavigationStart) {
    //         const url = event.url;
    //         const n = url.split('/');
    //         n.forEach(i => {if (i) {
    //           this.arrayUrl.push(i);
    //         }});
    //
    //       }
    //
    //     });
    /*router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        console.log("Footer: ",event);
        if (event.url === '/404' || event.url === '/cancellazione-newsletter') {
          this.hidden = false;
        } else {
          this.hidden = false;
        }
        console.log("Footer hidden: "+this.hidden);
      }
    });
    */
  }

  ngOnInit(): void {
    this.footerMenu = this.env.footerMenu; //this.menuService.getMenu('footer');
    this.userSubscription = this.authService.user.subscribe((user) => {
      this.userLogged = user;
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    let currentUrl = this.router.url.valueOf();
    console.log("footer currentUrl " + currentUrl);
    // Mostro sempre la CTA per il cerca spedizioni
    if (currentUrl.startsWith('/cerca-spedizioni')) {
      console.log("footer hideShowStickyFooterCta(0) ");
      this.hideShowStickyFooterCta(0);
      console.log("footer hideShowStickyFooterCta(0)...done. ");
    }
  }

  /**
   * Nasconde o mostra la sticky bottom bar per la CTA alla spedizione
   * aggiunge spazio sotto il footer in modo da non coprirlo
   * 
   * @param scrollY coordinata Y > scrollY > mostra la CTA, altrimente la nasconde
   */
  hideShowStickyFooterCta(scrollY: number): void {
    if (isPlatformBrowser(this.platformId)) {
      let currentUrl = this.router.url.valueOf();
      // console.log("hideShowStickyFooterCta " + currentUrl);
      /** footer CallToAction  */
      let footerCta: HTMLElement = document.getElementById("footer-cta");
      if (footerCta) {
        /** Footer filler */
        let footerCtaFiller: HTMLElement = document.getElementById('footer-cta-filler');
        /** Header filler */
        let headerCtaFiller: HTMLElement = document.getElementById('header-cta-filler');
        // Si aspetta lo scorrimento di parte della pagina (200px)
            // NON si mostra la CTA nella pagina di spezione (carrello) e preventivo
        if ((scrollY > 200 && (!currentUrl.startsWith('/account')) && (currentUrl != '/crea-spedizione') && (currentUrl != '/preventivo'))
          // La si mostra sempre per la il 
          || currentUrl.startsWith('/cerca-spedizioni')
        ) {
          // console.log("showStickyFooterCta: " + currentUrl);
          // Visualizza la CTA
          if (footerCta.style.bottom != '0px') {
            // Aggiunge altezza al filler per non coprire l'ultima riga del footer
            if (footerCtaFiller != null && footerCtaFiller.offsetHeight < footerCta.offsetHeight) {
              // footerCtaFiller.style.bottom = '0px';
              footerCtaFiller.style.height = footerCta.offsetHeight + 'px';
            }
            if (headerCtaFiller != null && headerCtaFiller.offsetHeight < footerCta.offsetHeight) {
              // footerCtaFiller.style.bottom = '0px';
              headerCtaFiller.style.height = footerCta.offsetHeight + 'px';
            }
            // Visualizza footer sticky CTA
            footerCta.style.bottom = '0px';
          }
        } else {
          if (footerCta.style.bottom != '-100%') {
            // console.log("hideStickyFooterCta: " + currentUrl);
            // Nascondi footer sticky CTA
            footerCta.style.bottom = '-100%';
            if (footerCtaFiller != null && footerCtaFiller.style.height != '0px') {
              // Porta a 0 l'altezza del filler
              footerCtaFiller.style.height = '0px';
              // footerCtaFiller.style.bottom = '-100%';
            }
          }
        }
      }
    }
  }
  /*
   ngAfterViewInit(): void {
     if (this.inter.getMainFormElementRef()) {
     // Se il form di inserimento non è visibile (parzialmente)
     // mostro il footer
     this.inter.createAndObserve(this.inter.getMainFormElementRef()).pipe(
     filter((isVisible: boolean) => this.showFooterCta = !isVisible)
     ).subscribe(data => { console.log('createAndObserve data ' + data) });
     //, switchMap(() => isVisi)
     //) .subscribe(data => { this.showFooterCta = !data });
     } else {
     this.showFooterCta = true;
     console.log("footer.component createAndObserve 'app-form-master' not found");
     }
   }
   */

  createUrl(path): string {
    const siteUrl = environment.hostname + '/';
    return this.createdPath = siteUrl + path;
  }
  /**
   * Scroll al main form (se presente)
   * Se non è presente il form di spezione naviga all'homepage del sito
   */
  goToMainForm(): void {
    let appFormMaster: HTMLElement = document.getElementById("cta-form-master"); //.querySelector('app-form-master') // 
    if (appFormMaster) {
      // Scroll al main form (spedizione)
      // this.scrollToTargetAdjusted(appFormMaster);
      this.scrollToTarget(appFormMaster);
    } else {
      // navigazione homepage
      this.router.navigate(['']);
    }
    return;
  }

  scrollToTarget(element: HTMLElement) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToTargetAdjusted(element: HTMLElement) {

    let headerOffset = 0; // 45;
    // console.log(element.getBoundingClientRect());
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  /** Mostra il footer CTA quando scroll > 1000 pixel */
  showFooterCta: boolean = false;

  // openChat() {
  //  if (window['LiveChatWidget']) window['LiveChatWidget'].call("maximize");
  // }
// TODO preventivo 
//  richiediPreventivo(event: Event) {
//    this.richiediPreventivoService.changeMessage("footer");
//  }

}


