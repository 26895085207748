import { Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { ModalService } from './services/modal.service';
import { MessageModalComponent } from './shared/components/message-modal/message-modal.component';

import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LocalStorage } from './services/storage-service';
import { UrlHistoryService } from './services/url-history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'speedit-fe';
  private destroy$ = new Subject<void>();
  env = environment;
  currentRoute: string;

  constructor(
  	private modalService: ModalService<any>,
    private authService: AuthService,
    private metaTagService: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorage,
    private urlHistorylService: UrlHistoryService) {

    (async () => {
    this.authService.checkAndSetSession();
    this.initializeErrors();
    // Commentato hide per abilitare icona LiveChat su tutte le pagine
    // if (window['LiveChatWidget'])  window['LiveChatWidget'].call("hide");
    if (environment.production && environment.indexingEnabled) {
      this.metaTagService.addTag({name: 'robots', content: 'index, follow'});
    } else {
      this.metaTagService.addTag({name: 'robots', content: 'noindex, nofollow'});
    }
    })();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        // Url corrente nell'history
        urlHistorylService.addUrl(event.url);
        if (!environment.production) {
          // Debug locale NavigationEnd
          console.log("app url  " + event.url + "       prev " + urlHistorylService.getPreviousUrl());
        }
      }
    });

    // Intercetto eventuali tag degli agenti
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.rootRoute(this.route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
    ).subscribe((route: ActivatedRoute) => {
      const tag = route.snapshot.queryParamMap.get('tag');
      authService.setAgentTag(tag);
    });

  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // recupero errore da interceptor
  private initializeErrors(): void {
    this.modalService
      .getErrors()
      .pipe(takeUntil(this.destroy$))
      .subscribe((errorType) => {
        // recupero le configurazioni in base al tipo di errore passato nell'intercptor
        const config =  this.modalService.getConfig(errorType);
        console.log('app component error: ', config);
        // apro la modale passando il componente e la configurazione
        this.modalService.open(MessageModalComponent, config);
      });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

}
