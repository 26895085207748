<nav class="breadcrumb" aria-label="breadcrumb">
  <!-- Gestione breadcrumb da backoffice -->
  <span *ngIf="breadcrumbService.hasBreadcrumbs">
    <ol class="grid grid-col-1 md:flex md:space-x-2" itemscope itemtype="https://schema.org/BreadcrumbList">
  
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a class="text-blue-200 hover:underline" itemprop="item" href="/"><span itemprop="name">{{env.company.name}}</span></a>
        <meta itemprop="position" content="1" />
      </li>
      <li *ngFor="let breadcrumb of breadcrumbService.breadcrumbs; let i=index; let last=last; let first=first"
        itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a *ngIf="!last" class="text-blue-200 hover:underline pl-0.5" itemscope itemtype="https://schema.org/WebPage"
          itemprop="item" [attr.itemid]="createBreadcrumbUrl(breadcrumb['url'])" [href]="createBreadcrumbUrl(breadcrumb['url'],false)"
          [routerLink]="createBreadcrumbUrl(breadcrumb['url'],false)">
          <span itemprop="name">{{breadcrumb['label']}}</span>
        </a>
        <span *ngIf="last" class="text-blue-200 pl-0.5" itemprop="name">{{breadcrumb['label']}}</span>
        <meta itemprop="position" [content]="i+2" />
      </li>
    </ol>
  </span>
  <!-- Gestione breadcrumb da path -->
  <span *ngIf="!breadcrumbService.hasBreadcrumbs">
    <ol class="grid grid-col-1 md:flex md:space-x-2" itemscope itemtype="https://schema.org/BreadcrumbList">

      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a class="text-blue-200 hover:underline" itemprop="item" href="/"><span itemprop="name">{{env.company.name}}</span></a>
        <meta itemprop="position" content="1" />
      </li>
      <ng-container *ngFor="let breadcrumbArray of this.newBreadcrumbs">
      
        <li *ngFor="let breadcrumb of breadcrumbArray.label; let i = index; let last = last; let first = first"
          itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
      
          <a *ngIf="!last" class="text-blue-200 hover:underline pl-0.5" itemscope itemtype="https://schema.org/WebPage"
            itemprop="item" [attr.itemid]="createUrl(breadcrumb['url'])" [href]="breadcrumb['url']"
            [routerLink]="breadcrumb['url']">
            <span itemprop="name">{{breadcrumb['label']}}</span>
          </a>
      
      
          <span *ngIf="last" class="text-blue-200 pl-0.5" itemprop="name">{{!!breadcrumb['label'] ? breadcrumb['label'] :
            (breadcrumbService.lastChildLabel$ | async)}} </span>
          <meta itemprop="position" [content]="i+2" />
        </li>
      
      </ng-container>
      </ol>
  </span>
</nav>

