import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found-component.module').then(m => m.NotFoundComponentModule),
  },
  {
    path: 'cancellazione-newsletter',
    loadChildren: () => import('./pages/cancellazione-newsletter/cancellazione-newsletter.module').then(m => m.CancellazioneNewsletterModule),
  },
  {
    path: 'blog/page/:id',
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: 'blog/categoria/:label',
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: 'blog/:path',
    loadChildren: () => import('./pages/blog/blog-detail/blog-detail.module').then(m => m.BlogDetailModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: 'assistenza',
    loadChildren: () => import('./pages/assistenza/assistenza.module').then(m => m.AssistenzaModule)
  },
  {
    path: 'fragment/:path',
    loadChildren: () => import('./pages/fragment/fragment.module').then(m => m.FragmentModule)
  },

  /* Diventata dinamica
  {
    path: 'come-spedire-un-pacco',
    loadChildren: () => import('./pages/come-spedire-un-pacco/come-spedire-un-pacco.module').then(m => m.ComeSpedireUnPaccoModule)
  },
  */
  {
    path: 'come-misurare-un-pacco',
    loadChildren: () => import('./pages/come-misurare-un-pacco/come-misurare-un-pacco.module').then(m => m.ComeMisurareUnPaccoModule)
  },

  {
    path: 'risparmiare-sulle-spedizioni',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/risparmiare-sulle-spedizioni/risparmiare-sulle-spedizioni.module').then(m => m.RisparmiareSulleSpedizioniModule)
  },
  {
    path: 'ricarica',
    loadChildren: () => import('./pages/ricarica/ricarica.module').then(m => m.RicaricaModule)
  },
  {
    path: 'come-imballare-un-pacco',
    loadChildren: () => import('./pages/come-imballare-un-pacco/come-imballare-un-pacco.module').then(m => m.ComeImballareUnPaccoModule)
  },
  {
    path: 'assicurazione-vettoriale',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/assicurazione-vettoriale/assicurazione-vettoriale.module').then(m => m.AssicurazioneVettorialeModule)
  },
  {
    path: 'lista-oggetti-proibiti-da-spedire',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/lista-oggetti-proibiti-da-spedire/lista-oggetti-proibiti-da-spedire.module').then(m => m.ListaOggettiProibitiDaSpedireModule)
  },
  {
    path: 'termini-e-condizioni',
    loadChildren: () => import('./pages/termini-e-condizioni/termini-e-condizioni.module').then(m => m.TerminiECondizioniModule)
  },
  {
    path: 'diritto-di-recesso',
    loadChildren: () => import('./pages/diritto-di-recesso/diritto-di-recesso.module').then(m => m.DirittoDiRecessoModule)
  },
  {
    path: 'cerca-punti-di-consegna',
    loadChildren: () => import('./pages/cerca-punti-di-consegna/cerca-punti-di-consegna.module').then(m => m.CercaPuntiDiConsegnaModule)
  },
  {
    path: 'tnt-point',
    loadChildren: () => import('./pages/cerca-punti-di-consegna-lite-tnt/cerca-punti-di-consegna-tnt.module').then(m => m.CercaPuntiDiConsegnaTntModule)
  },
  {
    path: 'ups-access-point',
    loadChildren: () => import('./pages/cerca-punti-di-consegna-lite-ups/cerca-punti-di-consegna-ups.module').then(m => m.CercaPuntiDiConsegnaUpsModule)
  },
  {
    path: 'fedex-locations',
    loadChildren: () => import('./pages/cerca-punti-di-consegna-lite-fedex/cerca-punti-di-consegna-fedex.module').then(m => m.CercaPuntiDiConsegnaFedexModule)
  },
  {
    path: 'fermo-point-brt',
    loadChildren: () => import('./pages/cerca-punti-di-consegna-lite-brt/cerca-punti-di-consegna-brt.module').then(m => m.CercaPuntiDiConsegnaBrtModule)
  },
  {
    path: 'cerca-spedizioni',
    loadChildren: () => import('./pages/cerca-spedizioni/cerca-spedizioni.module').then(m => m.CercaSpedizioniModule)
  },
  {
    path: 'cerca-spedizioni-subito',
    loadChildren: () => import('./pages/cerca-spedizioni-subito/cerca-spedizioni-subito.module').then(m => m.CercaSpedizioniSubitoModule)
  },
  {
    path: 'cerca-spedizioni-brt-bartolini',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/cerca-spedizioni-brt-bartolini/cerca-spedizioni-brt-bartolini.module').then(m => m.CercaSpedizioniBrtBartoliniModule)
  },
  {
    path: 'cerca-spedizioni-dhl',
    loadChildren: () => import('./pages/cerca-spedizioni-dhl/cerca-spedizioni-dhl.module').then(m => m.CercaSpedizioniDhlModule)
  },
  {
    path: 'cerca-spedizioni-dpd',
    loadChildren: () => import('./pages/cerca-spedizioni-dpd/cerca-spedizioni-dpd.module').then(m => m.CercaSpedizioniDpdModule)
  },
  {
    path: 'cerca-spedizioni-fedex',
    loadChildren: () => import('./pages/cerca-spedizioni-fedex/cerca-spedizioni-fedex.module').then(m => m.CercaSpedizioniFedexModule)
  },
  {
    path: 'cerca-spedizioni-gls',
    loadChildren: () => import('./pages/cerca-spedizioni-gls/cerca-spedizioni-gls.module').then(m => m.CercaSpedizioniGlsModule)
  },
  {
    path: 'cerca-spedizioni-nexive',
    loadChildren: () => import('./pages/cerca-spedizioni-nexive/cerca-spedizioni-nexive.module').then(m => m.CercaSpedizioniNexiveModule)
  },
  {
    path: 'cerca-spedizioni-poste-italiane-dovequando',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/cerca-spedizioni-poste-italiane-dovequando/cerca-spedizioni-poste-italiane-dovequando.module').then(m => m.CercaSpedizioniPosteItalianeDovequandoModule)
  },
  {
    path: 'cerca-spedizioni-royal-mail',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/cerca-spedizioni-royal-mail/cerca-spedizioni-royal-mail.module').then(m => m.CercaSpedizioniRoyalMailModule)
  },
  {
    path: 'cerca-spedizioni-sda-express-courrier',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('./pages/cerca-spedizioni-sda-express-courrier/cerca-spedizioni-sda-express-courrier.module').then(m => m.CercaSpedizioniSdaExpressCourrierModule)
  },
  {
    path: 'cerca-spedizioni-tnt',
    loadChildren: () => import('./pages/cerca-spedizioni-tnt/cerca-spedizioni-tnt.module').then(m => m.CercaSpedizioniTntModule)
  },
  {
    path: 'cerca-spedizioni-ups',
    loadChildren: () => import('./pages/cerca-spedizioni-ups/cerca-spedizioni-ups.module').then(m => m.CercaSpedizioniUpsModule)
  },
  {
    path: 'cerca-spedizioni-usps',
    loadChildren: () => import('./pages/cerca-spedizioni-usps/cerca-spedizioni-usps.module').then(m => m.CercaSpedizioniUspsModule)
  },
  {
    path: 'cerca-cap',
    loadChildren: () => import('./pages/cerca-cap/cerca-cap.module').then(m => m.CercaCapModule)
  },
  {
    path: 'domande-frequenti',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'crea-spedizione',
    loadChildren: () => import('./pages/create-shipment/create-shipment.module').then(m => m.CreateShipmentModule)
  },
  {
    path: 'carrello',
    loadChildren: () => import('./pages/checkout-generic/checkout-generic.module').then(m => m.CheckoutGenericModule)
  },
  {
    path: 'checkout/:transactionId',
    loadChildren: () => import('./pages/checkout-shipment/checkout-shipment.module').then(m => m.CheckoutShipmentModule)
  },
  
  {
    path: 'consents-subscription/:transactionId',
    loadChildren: () => import('./pages/consents-subscription/consents-subscription.module').then(m => m.ConsentsSubscriptionModule)
  },
  {
    path: 'ordine-effettuato-con-successo',
    loadChildren: () => import('./pages/thank-you/thank-you.module').then(m => m.ThankYouModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'account/ricarica',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'account/ricarica/elenco',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'account/ricarica/buy',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuardService]
  },
  {
    path:'accedi',
    loadChildren: () => import('./pages/auth/auth-page.module').then(m => m.AuthPageModule),
  },
  {
    path: 'registrati',
    loadChildren: () => import('./pages/auth/auth-page.module').then(m => m.AuthPageModule),
  },
  {
    path: 'promo',
    loadChildren: () => import('./pages/auth/auth-page.module').then(m => m.AuthPageModule),
  },
  {
    path: 'traccia-una-spedizione',
    loadChildren: () => import('./pages/traccia-spedizione/traccia-spedizione.module').then(m => m.TracciaSpedizioneModule)
  },
  {
    path: 'preventivo',
    loadChildren: () => import('./pages/richiedi-preventivo/richiedi-preventivo.module').then(m => m.RichiediPreventivoModule)
  },
  {
    path: 'site-map',
    loadChildren: () => import('./pages/sitemap/sitemap.module').then(m => m.SitemapModule)
  },
  {
    path: ':id',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      // initialNavigation: 'enabledBlocking', // TODO check se funziona
      enableTracing: false, 
      preloadingStrategy: NoPreloading, scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}