import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  userSubscription:Subscription;
  userLogged:User;
  uOpen: boolean = false;
  hidden: boolean = false;
  env;
  headerMenu = null;
  showNavbarMDropd: boolean[] = [false, false, false, false, false];

  constructor(private authService: AuthService, public menuService: MenuService, public router:Router ,private route: ActivatedRoute) { 
    //console.log("Header route.url: ",route.url);
    this.env = environment;
    /*router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        console.log("Header: ",event);
        if (event.url === '/404' || event.url === '/cancellazione-newsletter') {
          this.hidden = false;
        } else {
          this.hidden = false;
        }
        console.log("Header hidden: "+this.hidden);
      }
    });*/
  }

  ngOnInit(): void {
    this.headerMenu = this.env.headerMenu; //this.menuService.getMenu('header');
    
    this.userSubscription = this.authService.user.subscribe((user)=>{
      this.userLogged = user;
      // if (isDevMode()) console.log("header.component userLogged " + user);
    });
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe();
  }
  
  onFocusOutUserMenu(){
    setTimeout(()=>{
      this.uOpen = false;
    },200);
  }

  onLogout(){
    this.authService.logOut();
    this.uOpen=false;
    this.router.navigate(['']);
   
  }

  toggleNavbarMDropd(dropdown: number) {
    console.log("Toggle dropdown" + dropdown);
    this.showNavbarMDropd[dropdown] = !this.showNavbarMDropd[dropdown];
    for (let i = 0; i < this.showNavbarMDropd.length; i++) {
      if (i !== dropdown) {
        this.showNavbarMDropd[i] = false;
      }
    }
  }

  closeDropdown(i) {
    //this.showNavbarMDropd[i] = false;
    this.menuService.close();
  }

}
