<header class="bg-white shadow-md fixed w-full top-0 z-40" [class.hidden]="hidden" id="header-id">
    <div class="wrap py-3">
        <div class="grid grid-cols-4 lg:grid-cols-12 items-center justify-items-stretch">
            <div class="col-span-3">
                <a routerLink="/" href="/" (click)="menuService.close()">
                    <img src="{{env.company.logo}}" width="269" height="40" class="max-h-10 xl:max-h-12 w-auto" alt="{{env.company.name}}">
                </a>
            </div>
            <div class="hidden lg:block col-span-7 xl:col-span-7">
                <nav class="xl:text-center primary-nav flex flex-row gap-x-1">
                    <ul *ngIf="headerMenu && headerMenu.length>0">
                        <li class="dropdown" *ngFor="let item of headerMenu">
                            <a [routerLink]="item.link" [href]="item.link" [title]="item.alt" [ngClass]="{'dropbtn': item.submenu.length>0}">{{item.title}}</a>
                            <div class="dropdown-content">
                                <a *ngFor="let subitem of item.submenu" [routerLink]="subitem.link" [href]="subitem.link" [title]="subitem.alt">{{subitem.title}}</a>
                            </div>
                        </li>
                        <!-- Ricarica -->
                        <li *ngIf="env.ricaricaPiu">
                            <a routerLink="/ricarica" href="/ricarica" title="Acquista credito prepagato e risparmia">Ricarica</a>
                        </li>
                    </ul>
                    <ul *ngIf="!headerMenu || headerMenu.length==0">
                        <!-- Come spedire -->
                        <li class="dropdown">
                            <a routerLink="/come-spedire-un-pacco" href="/come-spedire-un-pacco" title="Come spedire un pacco"
                                class="dropbtn">Come spedire</a>
                            <div class="dropdown-content">
                                <a routerLink="/come-imballare-un-pacco" href="/come-imballare-un-pacco" title="Come imballare un pacco">Come
                                    imballare</a>
                                <a routerLink="/come-misurare-un-pacco" href="/come-misurare-un-pacco" title="Come misurare un pacco">Come
                                    misurare</a>
                            </div>
                        </li>
                        <!-- Servizi per spedire-->
                        <li class="dropdown">
                            <a routerLink="/servizi-di-spedizione" href="/servizi-di-spedizione" title="Tipologie di imballo"
                                class="dropbtn">Servizi</a>
                            <div class="dropdown-content">
                                <a routerLink="/spedire-un-pacco" href="/spedire-un-pacco" title="Spedire un pacco">Spedire un pacco</a>
                                <a routerLink="/spedire-una-busta" href="/spedire-una-busta" title="Spedire una busta">Spedire una busta</a>
                                <a routerLink="/spedire-un-pallet" href="/spedire-un-pallet" title="Spedire un pallet">Spedire un pallet</a>
                                <a routerLink="/spedire-vino" href="/spedire-vino" title="Spedire vino">Spedire vino</a>
                                <a routerLink="/spedire-olio" href="/spedire-olio" title="Spedire olio">Spedire olio</a>
                                <a routerLink="/spedire-una-valigia" href="/spedire-una-valigia" title="Spedire una valigia">Spedire una
                                    valigia</a>
                                <a routerLink="/spedire-una-bicicletta" href="/spedire-una-bicicletta" title="Spedire una bicletta">Spedire una
                                    bicicletta</a>
                            </div>
                        </li>
                        <!-- Spedizione -->
                        <li class="dropdown">
                            <a routerLink="/spedizione" href="/spedizione" title="Dove spedire" class="dropbtn">Spedizione</a>
                            <div class="dropdown-content">
                                <a routerLink="/spedire-in-italia" href="/spedire-in-italia" title="Spedire in Italia">Spedire in Italia</a>
                                <a routerLink="/spedire-in-europa" href="/spedire-in-europa" title="Spedire in Europa">Spedire in Europa</a>
                                <a routerLink="/spedire-all-estero" href="/spedire-all-estero" title="pedire all'estero">Spedire all'estero</a>
                                <a routerLink="/spedire-dall-estero" href="/spedire-dall-estero" title="Spedire dall'estero">Spedire
                                    dall'estero</a>
                                <a routerLink="/cerca-punti-di-consegna" href="/cerca-punti-di-consegna"
                                    title="Cerca punti di consegna dove lasciare i pacchi da spedire">Cerca punti di consegna</a>
                                <a routerLink="/cerca-cap" href="/cerca-cap" title="Cerca CAP Codici di Avviamento Postale">Cerca CAP</a>
                            </div>
                        </li>
                        <!-- Cerca spedizioni -->
                        <li class="dropdown">
                            <a routerLink="/cerca-spedizioni" href="/cerca-spedizioni" title="Cerca spedizioni" class="dropbtn">Cerca
                                spedizioni</a>
                            <div class="dropdown-content">
                                <a routerLink="/cerca-spedizioni-poste-italiane-dovequando" href="/cerca-spedizioni-poste-italiane-dovequando"
                                    title="Cerca spedizioni Poste Italiane">Cerca spedizioni Poste Italiane</a>
                                <a routerLink="/cerca-spedizioni-dhl" href="/cerca-spedizioni-dhl" title="Cerca spedizioni DHL">Cerca spedizioni
                                    DHL</a>
                                <a routerLink="/cerca-spedizioni-gls" href="/cerca-spedizioni-gls" title="Cerca spedizioni GLS">Cerca spedizioni
                                    GLS</a>
                                <a routerLink="/cerca-spedizioni-sda-express-courrier" href="/cerca-spedizioni-sda-express-courrier"
                                    title="SDA">Cerca
                                    spedizioni SDA</a>
                                <a routerLink="/cerca-spedizioni-ups" href="/cerca-spedizioni-ups" title="Cerca spedizioni UPS">Cerca spedizioni
                                    UPS</a>
                                <a routerLink="/cerca-spedizioni-brt-bartolini" href="/cerca-spedizioni-brt-bartolini"
                                    title="Cerca spedizioni Bartolini">Cerca spedizioni Bartolini</a>
                                <a routerLink="/cerca-spedizioni-tnt" href="/cerca-spedizioni-tnt" title="Cerca spedizioni TNT">Cerca spedizioni
                                    TNT</a>
                                <a routerLink="/cerca-spedizioni-nexive" href="/cerca-spedizioni-nexive" title="Cerca spedizioni Nexive">Cerca
                                    spedizioni Nexive</a>
                                <a routerLink="/cerca-spedizioni-fedex" href="/cerca-spedizioni-fedex" title="Cerca spedizioni Fedex">Cerca
                                    spedizioni Fedex</a>
                                <a routerLink="/cerca-spedizioni-dpd" href="/cerca-spedizioni-dpd" title="Cerca spedizioni DPD">Cerca spedizioni
                                    DPD</a>
                                <a routerLink="/cerca-spedizioni-usps" href="/cerca-spedizioni-usps" title="Cerca spedizioni USPS">Cerca
                                    spedizioni USPS</a>
                                <a routerLink="/cerca-spedizioni-royal-mail" href="/cerca-spedizioni-royal-mail"
                                    title="Cerca spedizioni Royal Mail">Cerca spedizioni Royal Mail</a>
                            </div>
                        </li>
                        <!-- Blog -->
                        <li class="dropdown">
                            <a routerLink="/blog" href="/blog/" title="Articoli del blog" class="dropbtn">Blog</a>
                            <div class="dropdown-content">
                                <a routerLink="/blog/categoria/trasportare" href="/blog/categoria/trasportare"
                                    title="Trasportare pacchi, pallet e merce: guide e consigli">Trasportare</a>
                                <a routerLink="/blog/categoria/corrispondenza" href="/blog/categoria/corrispondenza"
                                    title="Corrispondenza postale: spedire buste, lettere e raccomandate">Corrispondenza</a>
                                <a routerLink="/blog/categoria/ecommerce" href="/blog/categoria/ecommerce"
                                    title="Ecommerce: spedizioni aziendali di pacchi e buste">E-commerce</a>
                                <a routerLink="/blog/categoria/storie" href="/blog/categoria/storie"
                                    title="Storie e curiosità: trasporti e spedizioni">Storie e curiosità</a>
                            </div>
                        </li>
                        <!-- Ricarica -->
                        <li *ngIf="env.ricaricaPiu">
                            <a routerLink="/ricarica" href="/ricarica" title="Acquista credito prepagato e risparmia">Ricarica</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="hidden lg:block col-span-2 xl:col-span-2">
                <nav class="flex justify-end">
                    <div>
                        <a routerLink="/assistenza" href="/assistenza" class="link-ico justify-self-end py-3 block">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                </svg>
                                <span class="hidden lg:block" title="Assistenza clienti">Assistenza</span>
                            </div>
                        </a>
                    </div>
                    <div *ngIf="env.userEnabled" class="ml-6" (focusout)="onFocusOutUserMenu()" tabindex="0" style="white-space: nowrap;">
                        <a *ngIf="userLogged!=null" (click)="uOpen=!uOpen" class="link-ico justify-self-end py-3 block cursor-pointer">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <span>Le tue spedizioni</span>
                            </div>
                            
                        </a>
                        <ul *ngIf='uOpen' class="fixed shadow-xl bg-white p-1" >
                            <li style="display: block; float: none;" class="p-1"><a style="padding-bottom: 0px;" class="link-ico" routerLink="/account/shipments">Le tue spedizioni</a></li>
                            <li style="display: block; float: none;" class="p-1"><a style="padding-top: 0px; padding-bottom: 0px;" class="link-ico" routerLink="/account/addresses">La tua rubrica</a></li>
                            <li *ngIf="env.ricaricaPiu" style="display: block; float: none;" class="p-1"><a style="padding-top: 0px; padding-bottom: 0px;" class="link-ico" routerLink="/account/ricarica">Le tue ricariche</a></li>
                            <li style="display: block; float: none;" class="p-1"><a style="padding-top: 0px; padding-bottom: 0px;" class="link-ico" routerLink="/account/data">I tuoi dati</a></li>
                            <li style="display: block; float: none;" class="p-1"><a style="padding-top: 0px; padding-bottom: 0px;" class="link-ico cursor-pointer" (click)="onLogout()">Logout</a></li>
                        </ul>
                        

                        <a *ngIf="!userLogged" routerLink="/accedi" class="link-ico justify-self-end py-3 block">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <span>Accedi</span>
                            </div>
                        </a>

                    </div>
                </nav>
            </div>
            <div class="col-span-1 lg:hidden text-right">
                <button class="w-10 h-10 focus:outline-none text-blue-400 hover:text-blue-500" (click)="menuService.toggle()" aria-label="navigation">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mx-auto" [class.hidden]="menuService.isMenuOpened$ | async" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M6 7h14M8 12h12M4 17h16" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mx-auto" [class.hidden]="!(menuService.isMenuOpened$ | async)" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            
        </div>
    </div>
</header>


<div class="fixed top-14 bg-white left-0 right-0 bottom-0 z-30 pt-8 lg:hidden" [class.hidden]="!(menuService.isMenuOpened$ | async)">
    <!-- Navbar mobile -->
    <nav class="mobile-nav text-center flex flex-col">
        <ul *ngIf="headerMenu && headerMenu.length>0" class="flex flex-col">
            <li *ngFor="let item of headerMenu; let i = index" class="nav-item dropdown resources separated-left">
                <a routerLink="" (click)="toggleNavbarMDropd(i)"
                    id="navbarMDropd{{i}}" role="button" 
                    class="nav-link dropdown-toggle dropbtn">{{item.title}}</a>
                <div class="dropdown-menu" [class.hidden]="!showNavbarMDropd[i]">
                    <a *ngFor="let subitem of item.submenu" [routerLink]="subitem.link" [href]="subitem.link" [title]="subitem.alt" (click)="closeDropdown(i)"
                        class="dropdown-item">{{subitem.title}}</a>
                </div>
            </li>
        </ul>
    <ul *ngIf="!headerMenu || headerMenu.length==0" class="flex flex-col">
            <!-- navbar-nav first flex-xl-grow-1 justify-content-xl-start -->
            <!-- Come spedire -->
            <!-- href="javascript:void(0)" -->
            <li class="nav-item dropdown resources separated-left"><a routerLink="" (click)="toggleNavbarMDropd(0)"
                    id="navbarMDropd0" role="button" class="nav-link dropdown-toggle dropbtn">Come
                    spedire</a>
                <div class="dropdown-menu" [class.hidden]="!showNavbarMDropd[0]">
                    <a routerLink="/come-spedire-un-pacco" (click)="closeDropdown(0)"
                        href="/come-spedire-un-pacco" title="Come spedire un pacco" class="dropdown-item">Come spedire un
                        pacco</a>
                    <a routerLink="/come-imballare-un-pacco" href="/come-imballare-un-pacco" title="Come imballare un pacco" (click)="closeDropdown(0)"
                        class="dropdown-item">Come imballare</a>
                    <a routerLink="/come-misurare-un-pacco" href="/come-misurare-un-pacco" title="Come misurare un pacco" (click)="closeDropdown(0)"
                        class="dropdown-item">Come misurare</a>
                </div>
                </li>
                <li class="nav-item dropdown resources separated-left">
                    <a routerLink="" (click)="toggleNavbarMDropd(1)"
                        id="navbarMDropd1" role="button" class="nav-link dropdown-toggle dropbtn">Servizi</a>
                    <div class="dropdown-menu" [class.hidden]="!showNavbarMDropd[1]">
                    <a routerLink="/servizi-di-spedizione" (click)="closeDropdown(1)"
                        title="Servizi di spedizione" class="dropdown-item">Servizi di spedizione</a>
                    <!-- Servizi-->
                    <a routerLink="/spedire-un-pacco" href="/spedire-un-pacco" title="Spedire un pacco" (click)="closeDropdown(1)"
                        class="dropdown-item">Spedire un pacco</a>
                    <a routerLink="/spedire-una-busta" href="/spedire-una-busta" title="Spedire una busta" (click)="closeDropdown(1)"
                        class="dropdown-item">Spedire una busta</a>
                    <a routerLink="/spedire-un-pallet" href="/spedire-un-pallet" title="Spedire un pallet" (click)="closeDropdown(1)"
                        class="dropdown-item">Spedire un pallet</a>
                    <a routerLink="/spedire-vino" href="/spedire-vino" title="Spedire vino" (click)="closeDropdown(1)"
                        class="dropdown-item">Spedire
                        vino</a>
                    <a routerLink="/spedire-olio" href="/spedire-olio" title="Spedire olio" (click)="closeDropdown(1)"
                        class="dropdown-item">Spedire
                        olio</a>
                    <a routerLink="/spedire-una-valigia" href="/spedire-una-valigia" title="Spedire una valigia" (click)="closeDropdown(1)"
                        class="dropdown-item">Spedire una valigia</a>
                    <a routerLink="/spedire-una-bicicletta" href="/spedire-una-bicicletta" title="Spedire una bicletta" (click)="closeDropdown(1)"
                        class="dropdown-item">Spedire una bicicletta</a>
                </div>
            </li>
            <!-- Spedizioni -->
            <li class="nav-item dropdown resources separated-left"><a routerLink="" (click)="toggleNavbarMDropd(2)"
                    id="navbarMDropd2" role="button" class="nav-link dropdown-toggle dropbtn">Spedizioni</a>
                <div class="dropdown-menu" [class.hidden]="!showNavbarMDropd[2]">
                    <a routerLink="/spedizione" href="/spedizione" (click)="closeDropdown(2)"
                        title="Dove spedire">Dove
                        spedire</a>
                    <a routerLink="/spedire-in-italia" href="/spedire-in-italia" title="Spedire in Italia" (click)="closeDropdown(2)"
                        class="dropdown-item">Spedire in Italia</a>
                    <a routerLink="/spedire-in-europa" href="/spedire-in-europa" title="Spedire in Europa" (click)="closeDropdown(2)"
                        class="dropdown-item">Spedire in Europa</a>
                    <a routerLink="/spedire-all-estero" href="/spedire-all-estero" title="Spedire all'estero" (click)="closeDropdown(2)"
                        class="dropdown-item">Spedire
                        all'estero</a>
                    <a routerLink="/spedire-dall-estero" href="/spedire-dall-estero" title="Spedire dall'estero" (click)="closeDropdown(2)"
                        class="dropdown-item">Spedire dall'estero</a>
                    <a routerLink="/cerca-punti-di-consegna" href="/cerca-punti-di-consegna" (click)="closeDropdown(2)"
                        title="Cerca punti di consegna dove lasciare i pacchi da spedire">Cerca punti di consegna</a>
                    <a routerLink="/cerca-cap" href="/cerca-cap"  (click)="closeDropdown(2)"
                        title="Cerca CAP Codici di Avviamento Postale">Cerca CAP</a>
                </div>
            </li>
            <!-- Cerca spedizioni -->
            <li class="nav-item dropdown resources separated-left"><a routerLink="" (click)="toggleNavbarMDropd(3)"
                    id="navbarDropdown3" role="button" class="nav-link dropdown-toggle dropbtn">Cerca
                    spedizioni</a>
                <div class="dropdown-menu" [class.hidden]="!showNavbarMDropd[3]">
                    <a routerLink="/cerca-spedizioni" href="/cerca-spedizioni" title="Cerca spedizioni" (click)="closeDropdown(3)"
                        class="dropdown-item">Elenco corrieri</a>
                    <a routerLink="/cerca-spedizioni-poste-italiane-dovequando"
                        href="/cerca-spedizioni-poste-italiane-dovequando" title="Cerca spedizioni Poste Italiane" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca spedizioni Poste Italiane</a>
                    <a routerLink="/cerca-spedizioni-dhl" href="/cerca-spedizioni-dhl" title="Cerca spedizioni DHL" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca spedizioni
                        DHL</a>
                    <a routerLink="/cerca-spedizioni-gls" href="/cerca-spedizioni-gls" title="Cerca spedizioni GLS" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca spedizioni
                        GLS</a>
                    <a routerLink="/cerca-spedizioni-sda-express-courrier" href="/cerca-spedizioni-sda-express-courrier" (click)="closeDropdown(3)"
                        title="SDA" class="dropdown-item">Cerca spedizioni SDA</a>
                    <a routerLink="/cerca-spedizioni-ups" href="/cerca-spedizioni-ups" title="Cerca spedizioni UPS" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca spedizioni
                        UPS</a>
                    <a routerLink="/cerca-spedizioni-brt-bartolini" href="/cerca-spedizioni-brt-bartolini" (click)="closeDropdown(3)"
                        title="Cerca spedizioni Bartolini" class="dropdown-item">Cerca spedizioni Bartolini</a>
                    <a routerLink="/cerca-spedizioni-tnt" href="/cerca-spedizioni-tnt" title="Cerca spedizioni TNT" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca spedizioni
                        TNT</a>
                    <a routerLink="/cerca-spedizioni-nexive" href="/cerca-spedizioni-nexive" title="Cerca spedizioni Nexive" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca
                        spedizioni Nexive</a>
                    <a routerLink="/cerca-spedizioni-fedex" href="/cerca-spedizioni-fedex" title="Cerca spedizioni Fedex" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca
                        spedizioni Fedex</a>
                    <a routerLink="/cerca-spedizioni-dpd" href="/cerca-spedizioni-dpd" title="Cerca spedizioni DPD" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca spedizioni
                        DPD</a>
                    <a routerLink="/cerca-spedizioni-usps" href="/cerca-spedizioni-usps" title="Cerca spedizioni USPS" (click)="closeDropdown(3)"
                        class="dropdown-item">Cerca
                        spedizioni USPS</a>
                    <a routerLink="/cerca-spedizioni-royal-mail" href="/cerca-spedizioni-royal-mail" (click)="closeDropdown(3)"
                        title="Cerca spedizioni Royal Mail" class="dropdown-item">Cerca spedizioni Royal Mail</a>
                </div>
            </li>
            <!-- Blog -->
            <li class="nav-item dropdown resources separated-left"><a routerLink="" (click)="toggleNavbarMDropd(4)"
                    id="navbarDropdown4" role="button" class="nav-link dropdown-toggle dropbtn">Blog</a>
                <div class="dropdown-menu" [class.hidden]="!showNavbarMDropd[4]">
                    <a routerLink="/blog" href="/blog" title="Articoli del blog" (click)="closeDropdown(4)"
                        class="dropdown-item">Blog</a>
                    <a routerLink="/blog/categoria/trasportare" href="/blog/categoria/trasportare" (click)="closeDropdown(4)"
                        title="Trasportare pacchi, pallet e merce: guide e consigli" class="dropdown-item">Trasportare</a>
                    <a routerLink="/blog/categoria/corrispondenza" href="/blog/categoria/corrispondenza" (click)="closeDropdown(4)"
                        title="Corrispondenza postale: spedire buste, lettere e raccomandate"
                        class="dropdown-item">Corrispondenza</a>
                    <a routerLink="/blog/categoria/ecommerce" href="/blog/categoria/ecommerce" (click)="closeDropdown(4)"
                        title="Ecommerce: spedizioni aziendali di pacchi e buste" class="dropdown-item">Trasportare</a>
                    <a routerLink="/blog/categoria/storie" href="/blog/categoria/storie" (click)="closeDropdown(4)"
                        title="Corrispondenza postale: spedire buste, lettere e raccomandat" class="dropdown-item">Storie e
                        curiosità</a>
                </div>
            </li>
            <!-- Blog -->
            <li *ngIf="env.ricaricaPiu" class="nav-item dropdown resources separated-left"><a routerLink=""
                    (click)="toggleNavbarMDropd(5)" id="navbarDropdown5" role="button"
                    class="nav-link dropdown-toggle dropbtn">Ricarica</a>
                <div class="dropdown-menu" [class.hidden]="!showNavbarMDropd[5]">
                    <a routerLink="/ricarica" href="/ricarica" title="Acquista credito prepagato e risparmia" (click)="closeDropdown(5)"
                        class="dropdown-item">Ricarica</a>
                </div>
            </li>
        </ul>
    </nav>
    <div class="absolute bottom-0 left-0 right-0 bg-azul-100 p-3">
        <nav class="flex justify-center">
            <div>
                <a routerLink="/assistenza" href="/assistenza" (click)="menuService.close()" class="link-ico justify-self-end py-3 block">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                        </svg>
                        <span title="Assistenza clienti">Assistenza</span>
                    </div>
                </a>
            </div>
            <div *ngIf="env.userEnabled" class="ml-6">
                <a *ngIf="userLogged" routerLink="/account" (click)="menuService.close()" class="link-ico justify-self-end py-3 block">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        <span>Il tuo account</span>
                    </div>
                </a>
                <a *ngIf="!userLogged" routerLink="/accedi" (click)="menuService.close()" class="link-ico justify-self-end py-3 block">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        <span>Accedi</span>
                    </div>
                </a>
            </div>
            <div *ngIf="env.userEnabled" class="ml-6">
                <a *ngIf="userLogged" (click)="onLogout();menuService.toggle();" class="link-ico justify-self-end py-3 block">
                    <div>
                        <span title="Esci">Logout</span>
                    </div>
                </a>
            </div>
        </nav>
        <!-- Spazio vuoto per la CTA -->
        <div class="sticky-footer-filler" id="header-cta-filler"></div>
    </div>
</div>