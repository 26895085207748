<div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen text-center sm:block">
    <div class="wrap">
      <div *ngIf="backdrop" class="fixed inset-0 bg-white bg-opacity-80 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div class="card-boxed modal w-4/4 lg:w-3/4">
        <div *ngIf="!html">
          <h3 id="modal-title">
            {{title}}
          </h3>
          <p>
            {{text}}
          </p>
        </div>
        <div *ngIf="html" [innerHTML]="html">
        </div>
        <div class="absolute right-0 top-0">
          <button type="button" (click)="close()" class="w-10 h-10">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-auto text-blue-500 hover:text-azul-500 transition-colors" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 18L18 6M6 6l12 12">
                </path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>