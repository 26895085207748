import { environment } from './../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Inject, Injectable, InjectionToken, isDevMode } from '@angular/core';
import { timeout, catchError, finalize, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { ModalService } from '../../services/modal.service';
import { AuthService } from 'src/app/services/auth.service';
import { makeStateKey, TransferState } from '@angular/platform-browser';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  /** whitelist - final path delle url che non prevedono la modale */
  whiteList: Array<string>;

  constructor(private router: Router,
    private authService: AuthService,
    private transferState: TransferState,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private modalService: ModalService<any>,
    public loaderService: LoaderService,
    public http: HttpClient,
    ) {
    this.whiteList = ['shipment-type', 'shipment-type2', 'shipment-type3'];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // si visualizza il loader
    // this.loaderService.show();
    // aggiungo timeout dopo il quale la chiamata va in errore, timeout configurabile da app.module
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    // possibile custom timeout http.get('/your/url/here', { headers: new HttpHeaders({ timeout: `${20000}` }) })
    //
    /*
    if (!window.navigator.onLine) {
      const InternetError = 'Errore internet';
      this.modalService.errorType('internetError');
      this.loaderService.hide();
      return throwError(InternetError);
    }
    */

    if (this.authService.isTokenPresent()) {
      req = req.clone({
        setHeaders: {
          Authorization: "Bearer "+this.authService.getToken()
        }
      });
    }
    
    // Gestione solo delle GET //this.transferStateWhiteList.findIndex(entry => req.url.includes(entry)) != -1
    if (req.method === 'GET') {
      // Risposta presente Server Side
      if (this.transferState.hasKey(makeStateKey(req.url))) {
        const storedResponse: string = this.transferState.get(makeStateKey(req.url), null);
        if (storedResponse) {
          const response = new HttpResponse({ body: storedResponse, status: 200 });
          if (isDevMode()) console.debug("BrowserInterceptor OK storedResponse for " + req.url);

          // Ritorna la risposta 
          return of(response).pipe(
            // stop del loader spinner
            finalize(() => this.loaderService.hide())
          );
        }
      } else {
        if (isDevMode()) console.debug("BrowserInterceptor NO storedResponse  for " + req.url);
      }
    }
    // (httpEvent: any)
    return next.handle(req)
      .pipe(timeout(timeoutValueNumeric),
        tap((httpEvent: any) => {
          // Skip request
          if (httpEvent && httpEvent.type === 0) {
            // if (isDevMode()) console.log("BrowserInterceptor httpEvent type 0 " + httpEvent.url);
            return;
          }
          // Process response
          if (httpEvent instanceof HttpResponse) {
            if (isDevMode()) console.log("BrowserInterceptor HttpResponse " + httpEvent.url);
            let refreshToken = null;
            let oldToken = null;
            // If JWT token is refreshed
            if (httpEvent.headers.has('X-Refresh-Auth')) {
              refreshToken = httpEvent.headers.get('X-Refresh-Auth');
              if (refreshToken && refreshToken.startsWith('Bearer')) {
                refreshToken = refreshToken.replace('Bearer ', '');
                oldToken = req.headers.get('Authorization').replace('Bearer ', '');
              }
              if (refreshToken && refreshToken != oldToken) {
                if (isDevMode()) console.log("BrowserInterceptor refreshToken");
                // Refresh user auth token (local storage or session)
                this.authService.setRefreshedToken(refreshToken);
                if (isDevMode()) console.log("BrowserInterceptor refreshToken...done.");
              }
            }
          }
        }),
        // chiudo il loader al finalize della chiamata
        finalize(() => this.loaderService.hide()),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (isDevMode()) console.error('GlobalHttpInterceptor HttpErrorResponse');
            if (isDevMode()) console.error(`[${error.status} - ${error.statusText}] Response from: ${error.url} - ${error.message}`, error.error);
            // verifico che la url che va in errore non faccia parte della whitelist
            if (this.isValidUrl(error.url)) {
              // 500
              if (error.status === 500) {
                //this.handleError(error); //non serve cè già lato server l'eccezione
                if (isDevMode()) console.debug("BrowserInterceptor intercept 500 " + error.url);
                this.modalService.errorType('');
                return;
              }
              // 401
              if (error.status == 401) {
                if (isDevMode()) console.debug("BrowserInterceptor intercept 401" + error.url);
                this.authService.logOut();
                this.router.navigate(['accedi']);
              }
              // 404
              if (error.status == 404) {
                // this.authService.logOut();
                if (isDevMode()) {
                  this.handleError(error);
                }
                if (error.url.startsWith(environment.apiUrl + '/landing') || !error.url.startsWith(environment.apiUrl)) {
                  // Landing non trovata o pagina NON delle API
                  if (isDevMode()) console.debug("BrowserInterceptor intercept 404" + error.url);
                  this.router.navigate(['404']);
                } else {
                  // Non vado a pagina 404 per pagine API NON trovate
                  if (isDevMode()) console.error("BrowserInterceptor intercept 404 " + error.url);
                }
              }
            }
          }
          return throwError(error);
        })
      );
  }

  // funzione che verifica la presenza della requestUrl nella whitelist
  private isValidUrl(requestUrl: string): boolean {
    const destination = this.getPath(requestUrl);
    return !this.whiteList.includes(destination);
  }

  // ottengo la path della url dopo la stringa '/api' per semplificare la verifica della whitelist e la gestione errore
  getPath(requestUrl: string): string {
    const positionIndicator = 'api/';
    const position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      return requestUrl.substr(position + positionIndicator.length);
    }
  }

  handleError(error: any) {
    
    const userAgent = window.navigator.userAgent;
    const userUrl = window.location.href;
    const url = environment.apiUrl + '/clienterror';

    console.log('GlobalHttpInterceptor GlobalErrorHandler api url: ', url);

    let message = null;
    if (error.message) {
        message = error.message;
    }
    
    const errorObj = { type: 'http', errorMessage: message, userUrl: userUrl, userAgent: userAgent, localStorage: null, stacktrace: null }

    console.log('GlobalHttpInterceptor GlobalErrorHandler send: ', errorObj);

    try {
      this.http.post(url, errorObj).subscribe(response => {
        console.log('GlobalHttpInterceptor GlobalErrorHandler send: ', response);
        console.log('GlobalHttpInterceptor GlobalErrorHandler send: done');
      }, (error: HttpErrorResponse) => {
        console.log('GlobalHttpInterceptor GlobalErrorHandler send error: ', error);
      });
    } catch(e) {
      console.log('GlobalHttpInterceptor GlobalErrorHandler send error: ', e);
    }
  }
}
