export const environment = {
  engineTerms: true,
  production: false,
  indexingEnabled: false,
  // Area utente abilitata
  userEnabled: true,

  hostname: 'https://www.spediresubito.dev',
  
  defaultOgImg: '/assets/img/img-ogmeta.jpeg',

  apiUrl: 'https://www.spediresubito.dev/bend/api',
  url: 'https://www.spediresubito.dev/bend',
  //trustPilotApiUrl: 'https://api.spediresubito.dev/api',
  trustPilotImageUrl: 'https://www.spediresubito.dev/bend/storage/media/1722267229-spediresubito-recensioni.jpg',
  
  /*
  apiUrl: 'http://51.38.226.120:8080/api',
  url: 'http://51.38.226.120:8080',
  */

  // No invocazione API per le chiamate di default dell'homepage/landing
  staticDefaultApiServicesResponse: true,
  // Cache RAM API 60 minuti
  cachedApiValidity: 3600000,
  // Mostra le invocazioni della cache
  showCacheHitMiss: true,
  // Compressione SSR locale disabilitata
  ssrCompress: false,
  // Cache SSR pagine
  ssrAnonymousPageCache: true,

  google : {
    apiKey: '',
    sdkUrl: 'https://pay.google.com/gp/p/js/pay.js',
    merchantId: 'BCR2DN4TWKPIPC2Z',
    merchantName: 'SpedireSubito',
    gtm: 'GTM-55FK5ZP'
  },

  apple: {
    merchantId: 'merchant.com.spediresubito',
    merchantName: 'SpedireSubito',
    sdkUrl: 'https://js.applepay.com/v3'
  },

  /* live 'pk_live_51J0lGwAorQV3aYNjfvU6N5dtmKrMCL6KlpLgzQXXhWMUDj1z3f8VoVcQYYUpijzZVUWo3n3tgnsLEgI1lw9J4fJj00835Wem8K' */
  stripe: {
    publicKey: 'pk_test_51J0lGwAorQV3aYNjqWQFynyOTgDMFlaSvuUSRfmSBg9uABSKpHKXfGKKnSjdgB5zmKKnMlGJMZJj1YXznWTeyHTl00wekqI9lU', 
    sdkUrl: 'https://js.stripe.com/v3',
    applePayDomain: 'www.spediresubito.dev'
  },

  satispay: {
    sdkUrl: 'https://staging.online.satispay.com/web-button.js'
  },
/* spediresubito.dev
  paypal: {
    sdkUrl: 'https://www.paypal.com/sdk/js?currency=EUR&client-id=AZsFMSfSV6XyfCbtzrWip_4Bv9Au0ZHH1YTXxKYM7iRPZ0P5MXXlfRSqTmzKVr5Xygpz22UQuEMnh_ze&disable-funding=card,sofort,mybank'
  },
*/
  /*
  Sandbox paypal Luca
  */
  paypal: {
    sdkUrl: 'https://www.paypal.com/sdk/js?currency=EUR&client-id=AaRSyZlODqx_H5XORW18Xvm8pjzHnhWTjm7A2QPpXevbyM7zGMPEqGUB46jVAQOL8h7NcmQ-cdU4SLvG&disable-funding=card,sofort,mybank'
  },

  sendy: {
    listAliasStep4: 'dev'
  },

  company_id: 1,
  company: {
    "name": "SpedireSubito",
    "phone": "+39 0542 641604",
    "domain": "spediresubito.com",
    "logo": "/assets/img/logo.svg"
  },

  /* WebChat uChat pagina assistenza */
  uchatWebChatUrl: "https://www.uchat.com.au/js/widget/xhmoaspcxv5owqru/float.js",

  /** Disabilita (step-5) le prenotazioni per ritiri il giorno successivo dalle 19:30 alle 24 */
  disableNextDayEvening: true,
  
  /** Borsellino di ricarica abilitato */
  ricaricaPiu: true,

  /** Orari feriali assistenza */
  supportHours: 'dalle 9:00 alle 19:30',

  calendly_url: 'https://calendly.com/spediamoweb/1h',
  tidycal_book_60: 'spediamowebcom/call60',
  tidycal_book_30: 'spediamowebcom/call30',
  tidycal_script: 'https://asset-tidycal.b-cdn.net/js/embed.js',

  /** Consente creazione di spedizione per utenti non autenticati */
  anonymousAccess: true,

  registration: {
    type: 'private',
    enabled: true
  }
  
};
