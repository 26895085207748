<footer class="bg-blue-500 text-sm" [class.hidden]="hidden">
  <div class="wrap">
    <div class="grid grid-cols-1 md:flex md:flex-wrap md:flex-row py-5 border-b border-white border-opacity-10">
      <div class="block">
        <span class="text-blue-200 mr-2">Ti trovi in:</span>
      </div>
      <div>
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
  </div>

  <div class="wrap py-10">
    <div class="grid grid-cols-1 space-y-6 sm:grid-cols-2 lg:grid-cols-5 lg:space-y-0 gap-6">
      
      <!-- devo mettere condizione solo se presente footerùmenu -->
      <ng-container *ngIf="footerMenu && footerMenu.length>0"></ng-container>
      <div class="col-span-1" *ngFor="let item of footerMenu">
        <span class="text-base font-bold text-blue-100 pb-4 lg:pb-2 block">{{item.title}}</span>
        <ul>
          <li *ngFor="let link of item.links">
            <a [routerLink]="link.url" [href]="link.url" class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">{{link.title}}</a>
          </li>
        </ul>
      </div>

      <ng-container *ngIf="!footerMenu || footerMenu.length==0">
      <div class="col-span-1">
        <span class="text-base font-bold text-blue-100 pb-4 lg:pb-2 block">Cosa puoi spedire</span>
        <ul>
          <li><a routerLink="/spedire-un-pacco" href="/spedire-un-pacco"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire un pacco</a>
          </li>
          <li><a routerLink="/spedire-una-busta" href="/spedire-una-busta"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire una busta</a>
          </li>
          <li><a routerLink="/spedire-un-pallet" href="/spedire-un-pallet"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire un pallet</a></li>
          <li><a routerLink="/spedire-vino" href="/spedire-vino"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire vino</a></li>
          <li><a routerLink="/spedire-olio" href="/spedire-olio"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire olio</a></li>
          <li><a routerLink="/spedire-una-valigia" href="/spedire-una-valigia"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire una valigia</a></li>
          <li><a routerLink="/spedire-una-bicicletta" href="/spedire-una-bicicletta"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire una bicicletta</a></li>
        </ul>
      </div>

      <div class="col-span-1">
        <span class="text-base font-bold text-blue-100 pb-4 lg:pb-2 block">Dove puoi spedire</span>
        <ul>
          <li><a routerLink="/spedire-in-italia" href="/spedire-in-italia"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire in Italia</a></li>
          <li><a routerLink="/spedire-in-europa" href="/spedire-in-europa"
                class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire in Europa</a></li>
          <li><a routerLink="/spedire-all-estero" href="/spedire-all-estero"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire all'estero</a></li>
          <li><a routerLink="/spedire-dall-estero" href="/spedire-dall-estero"
                class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire dall'estero</a></li>
          <li><a routerLink="/corriere-espresso" href="/corriere-espresso"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Spedire con corriere
              espresso</a></li>
          
          <!-- li -->
          <!-- a routerLink="/preventivo" href="/preventivo" (click)=richiediPreventivo($event)
                        class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Preventivo spedizione</a -->
          <!-- /li -->
        </ul>
      </div>
      <div class="col-span-1"> 
        <span class="text-base font-bold text-blue-100 pb-4 lg:pb-2 block">Risorse</span>
        <ul>
          <li><a routerLink="/come-spedire-un-pacco" href="/come-spedire-un-pacco"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Come spedire</a></li>
          <li><a routerLink="/come-imballare-un-pacco" href="/come-imballare-un-pacco"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Come imballare</a></li>
          <li><a routerLink="/come-misurare-un-pacco" href="/come-misurare-un-pacco"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Come misurare
              l'imballo</a></li>
          <li><a routerLink="/lista-oggetti-proibiti-da-spedire" href="/lista-oggetti-proibiti-da-spedire"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white">Oggetti proibiti</a></li>
          <li><a routerLink="/cerca-spedizioni" href="/cerca-spedizioni"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Cerca spedizioni</a></li>
          <li><a routerLink="/cerca-punti-di-consegna" href="/cerca-punti-di-consegna"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Cerca punti di
              consegna</a></li>
          <li><a routerLink="/cerca-cap" href="/cerca-cap"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Cerca CAP</a></li>
        </ul>
      </div>
      <div class="col-span-1">
        <span class="text-base font-bold text-blue-100 pb-4 lg:pb-2 block">Condizioni</span>
        <ul>

          <li><a routerLink="/termini-e-condizioni" href="/termini-e-condizioni"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Termini e condizioni</a>
          </li>
          <li><a routerLink="/diritto-di-recesso" href="/diritto-di-recesso"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Diritto di recesso</a>
          </li>
          <li><a routerLink="/privacy-policy" href="/privacy-policy"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Privacy Policy</a></li>
          <li><a routerLink="/cookie-policy" href="/cookie-policy"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Cookie Policy</a></li>
          <li><a routerLink="/assicurazione-vettoriale" href="/assicurazione-vettoriale"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Assicurazione
              vettoriale</a></li>
        </ul>
      </div>
      </ng-container>
      <!-- Assistenza clienti -->
      <div class="col-span-1">
        <a routerLink="/assistenza" href="/assistenza" title="Assistenza clienti"><span
            class="text-base font-bold text-blue-100 pb-4 lg:pb-2 block">Assistenza</span></a>
        <ul>
          <li><a routerLink="/assistenza" href="/assistenza" title="Assistenza clienti"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Assistenza clienti</a></li>
          <li>
                  <a  target="_blank" href="https://wa.me/393801416777"
                      class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm" alt="Assistenza via Whatsapp">
                      <div class="flex flex-row gap-3">
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            width="16" height="16" 
                            fill="currentColor" 
                            class="bi bi-whatsapp mt-1" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                          </svg> 
                        <span>Whatsapp</span>
                      </div>
                  </a>
          </li>
          <li><a routerLink="/domande-frequenti" href="/domande-frequenti"
              class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">Domande frequenti</a></li>
          <!-- Accedi -->
          <li *ngIf="!userLogged" class="py-3 lg:py-0.5 block text-blue-200 hover:text-white text-base lg:text-sm">
            <!-- a class="text-base font-bold text-blue-100 pb-4 lg:pb-2" routerLink="/accedi" >Accedi</a -->
            &nbsp;
          </li>
          <!-- Registrati -->
          <li *ngIf="!userLogged">
            <a routerLink="/registrati" class="text-base font-bold text-blue-100 pb-4 lg:pb-2">Registrati</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Seguici / supportiamo -->
  <div class="wrap py-10">
    <div class="grid grid-cols-1 space-y-6 sm:grid-cols-2 lg:grid-cols-5 lg:space-y-0 gap-6 items-center">
      <!-- Supportiamo -->
      <div class="col-span-1 text-blue-200">
        <div class="flex flex-col gap-4">
          <span class="text-base font-bold text-blue-100 block">Supportiamo</span>
          <div>
            <a routerLink="/spediresubito-treedom" href="/spediresubito-treedom" title="Spediresubito per Treedom"><img
                [lazyLoad]="'assets/img/svg/logoTreedom.svg'"
                [defaultImage]="'assets/img/svg/grayRect.svg'" alt="Logo Treedom" height="40" width="100" /></a>
          </div>
          <div>
            <a routerLink="/spediresubito-save-the-children" href="/spediresubito-save-the-children"
              title="{{env.company.name}} per Save the children"><img
                [lazyLoad]="'assets/img/svg/logoSaveTheChildren.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                alt="Logo Save the Children" height="32" width="158" /></a>
          </div>
        </div>
      </div>
      <!-- Seguici -->
      <div class="col-span-1 text-blue-200">
        <div class="flex flex-row gap-4 items-center">
          <span class="text-base font-bold text-blue-100 block">Seguici su</span>
          <div class="flex flex-row gap-3">
            <a href="https://it-it.facebook.com/spediresubito.official/" target="_blank"
              class="hover:text-white text-blue-300" aria-label="Facebook" rel="noopener" title="Facebook">
              <img [lazyLoad]="'assets/img/svg/logoFacebook.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                class="w-8 h-8 rounded" alt="Logo Facebook" height="40"
                width="40" />
            </a>
            <a href="https://www.instagram.com/spediresubito_com/" target="_blank" class="hover:text-white text-blue-300"
              aria-label="Instagram" rel="noopener" title="Instagram">
              <img [lazyLoad]="'assets/img/svg/logoInstagram.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                class="w-8 h-8 rounded" alt="Logo Instagram" height="40"
                width="40" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-blue-700" id="footer-lastrow">
    <div class="wrap">
      <div class="py-3">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-3 text-blue-300 items-center">
          <div class="col-span-1 text-center lg:text-left">&copy;{{currentDate.getFullYear()}} {{env.company.name}} &middot;
            Tutti i diritti sono riservati &middot; P.IVA 06856881211 &middot; REA 844069 &middot; <a href="/site-map">Sitemap</a>
            </div>
          <div class="col-span-1 text-center lg:text-right">
            <div class="flex flex-wrap flex-row justify-center lg:justify-end gap-1">
              <div class="w-auto">
                <img [lazyLoad]="'assets/img/svg/logoApplePay.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                  alt="Logo pagamento con Apple Pay" width="42" height="27" />
                </div>
                <div class="w-auto">
                  <img [lazyLoad]="'assets/img/svg/logoGooglePay.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                    alt="Logo pagamento con Google Pay" width="42" height="27" />
                  </div>
                  <div class="w-auto">
                  <img [lazyLoad]="'assets/img/svg/logoPaypal.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                    alt="Logo pagamento con Paypal" width="42" height="27" />
                  </div>
                  <div class="w-auto">
                    <img [lazyLoad]="'assets/img/svg/logoSatispay.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                      alt="Logo pagamento con Satispay" width="42" height="27" />
                  </div>
                  <div class="w-auto">
                    <img [lazyLoad]="'assets/img/svg/logoVisa.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                      alt="Logo pagamento Visa"
                  width="42" height="27" />
                  </div>
                  <div class="w-auto">
                    <img [lazyLoad]="'assets/img/svg/logoMastercard.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                      alt="Logo pagamento Mastercard" width="42" height="27" />
                    </div>
                    <div class="w-auto">
                    <img [lazyLoad]="'assets/img/svg/logoMaestro.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                      alt="Logo pagamento Maestro" width="42" height="27" />
                    </div>
                    <div class="w-auto">
                    <img [lazyLoad]="'assets/img/svg/logoAmericanExpress.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                      alt="Logo pagamento American Express" width="42" height="27" />
                    </div>
                    <div class="w-auto">
                    <img [lazyLoad]="'assets/img/svg/logoPostepay.svg'" [defaultImage]="'assets/img/svg/grayRect.svg'"
                      alt="Logo pagamento Postepay" width="42" height="27" />
                    </div>
                    <div style="width:42px;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Spazio vuoto per la CTA -->
  <div class="sticky-footer-filler" id="footer-cta-filler"></div>
  <!-- CTA spedizione -->
  <div class="sticky-footer w-full" id="footer-cta">
    <div class="wrap text-center sticky-footer-text">
      <!-- pr-10-->
      <div class="cta-text-with-chat">Sei pronto a spedire con i migliori corrieri? &nbsp;&nbsp;<button
          (click)="goToMainForm()" class="btn btn-lg btn-primary sticky-footer-button"
          id="cta-footer-spedisciora"><span>Spedisci
            Subito</span></button></div>
    </div>
  </div>
  <!-- Bottone uchat -->
  <a href="https://wa.me/393801416777" target="_blank" alt="Assistenza via Whatsapp"><span class="chatBtnShadow"><svg
        height="40" width="40">
        <circle cx="20" cy="20" r="20" />
      </svg></span><img class="chatBtn" src="assets/img/whatsapp.svg" alt="Logo Whatsapp"></a>
</footer>