import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, UserTempCode } from '../models/user.model';
import { GoogleTagManagerService } from './angular-google-tag-manager/angular-google-tag-manager.service';
import { LocalStorage, SessionStorage } from './storage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);
  tempData = new BehaviorSubject<UserTempCode>(null);
  env: any;

  constructor(
    protected http: HttpClient, 
    private sessionStorage: SessionStorage, 
    private localStorage: LocalStorage,
    private googleTagManagerService: GoogleTagManagerService) { 
      this.env = environment;
  }


  login(email:string, password:string,remember:boolean){
    return this.http.post(`${environment.apiUrl}/login`, {
      email,
      password,
      'company_id': this.env.company_id
    }).pipe(
      map(response => {
        this.setUser(response['user']);
        this._setToken(response['token'],remember);
      }),
      catchError(err => {
        console.log('errors login: ', err);
        return throwError(err);
    })
    )

  }

  signup(email: string, password: string, name: String, welcomeCoupon: boolean = false, tag: string = null) {
    return this.http.post(`${environment.apiUrl}/customers/register`, {
      name,
      email,
      password,
      welcome_coupon: welcomeCoupon,
      company_id: this.env.company_id,
      tag: tag
    }).pipe(
      map(response => {
        this.tempData.next({id:response['user_id'],email: response['email']});
      }),
      catchError(err => {
        console.log('errors signup: ', err);
        return throwError(err);
    })
    )
  }

  activate(id:number, code:String){
    return this.http.get(`${environment.apiUrl}/customers/activate/${id}/${code}`).pipe(
      map(response => {
       this.setUser(response['user']);
       this._setToken(response['token'],true);
      }),
      catchError(err => {
        console.log('errors activate: ', err);
        return throwError(err);
    }))
  }

  reminder(email:String){
    return this.http.post(`${environment.apiUrl}/reminder`,{
      email,
    }).pipe(
      map(response => {
        this.tempData.next({id:response['user_id'],email: response['email']});
      }),
      catchError(err => {
        console.log('errors reminder: ', err);
        return throwError(err);
    }))
  }

  activation(email:String){
    return this.http.post(`${environment.apiUrl}/customers/activation`,{
      email,
    }).pipe(
      map(response => {
        this.tempData.next({id:response['user_id'],email: response['email']});
      }),
      catchError(err => {
        console.log('errors activation: ', err);
        return throwError(err);
    }))
  }

  checkReminder(id:number, code:String){
    return this.http.get(`${environment.apiUrl}/reminder/check/${id}/${code}`).pipe(
      map(response => {
        this.tempData.next({id:response['user_id'], code: response['code'],email: response['email']});
      }),
      catchError(err => {
        console.log('errors checkReminder: ', err);
        return throwError(err);
    }))
  }

  reset(id:number,code:number, password:string, confirmPassword:String){
    return this.http.post(`${environment.apiUrl}/reset-psw`,{
      user_id:id,
      code,
      password,
      password_confirm:confirmPassword
    }).pipe(
      map(response => {
        console.log(response);
      }),
      catchError(err => {
        console.log('errors reset: ', err);
        return throwError(err);
    }))
  }

  refreshUser(){
    return this.http.get(`${environment.apiUrl}/user`).pipe(map((response) => {
      if (isDevMode()) console.log(response);
      if (response['user'] && response['user'].id) {
        this.setUser(response['user']);
      }
    }),catchError((err)=>{
      console.log('auth.service errors patchUser: ', err);
      this.logOut();
      // TODO Check if ok
      return throwError(err);
    }));

}


    private _setToken(token,remember:boolean) {
      if (remember) {
        this.localStorage.setItem('token', token);
      } else {
        this.sessionStorage.setItem('token', token);
      }
  }   

  /**
   * 
   * @param token Refreshed JWT auth token
   */
  public setRefreshedToken(token: string) {
    if (isDevMode()) console.log("setRefreshedToken ");
    if (token) {
      let remember = this.localStorage.getItem('token') ? true : false;
      this._setToken(token, remember);
    }
  }

  setUser(user) {
    this.user.next(user);
    this.localStorage.setItem('user', JSON.stringify(user));
    if (user && user.sender_address && user.sender_address.length > 0) {
      user.sender_address[0].save_to_address_book = false;
      this.localStorage.setItem('sender_address', JSON.stringify(user.sender_address[0]));
      this.localStorage.removeItem('recipient_address');
    }
    if (user && user.billing_address && user.billing_address.length > 0) {
      user.billing_address[0].save_to_address_book = false;
      this.localStorage.setItem('billing_address', JSON.stringify(user.billing_address[0]));
      this.localStorage.setItem('customerType', (user.customer_type && 'company' == user.customer_type ? 'company' : 'private'));
      this.localStorage.setItem('getInvoice', user.get_invoice);
    }
    if (user && user.id) {
      this.googleTagManagerService.pushUserGa4(user.id);
    } else {
      if (isDevMode()) console.warn("authService setUser " + user);
    }

  }

  /**
   * 
   * @returns token JWT saved in localStorage or sessionStorage
   */
   getToken(){
    const token = this.localStorage.getItem('token') || this.sessionStorage.getItem('token');
    return token;
   }

   logOut(){
     try {
       let userId = null;
       if (this.user && this.user.getValue() && this.user.getValue().id) {
         // id utente
         userId = this.user.getValue().id;
         this.googleTagManagerService.clearUserGa4('' + userId);
       } else {
         this.googleTagManagerService.clearUserGa4();
       }
     } catch (error) {
       console.error(error);
     }
     this.localStorage.removeItem('token');
     this.sessionStorage.removeItem('token');
     this.localStorage.removeItem('user');
     // Rimossi i dati dello step4 e invoice per evitare problemi con customerType, reason e invoice.
     this.localStorage.removeItem('step4Data');
     this.localStorage.removeItem('getInvoice');
     this.user.next(null);

     this.localStorage.removeItem('__paypal_sdk_storage__');

     this.http.get(`${environment.apiUrl}/logout`).toPromise();
   }
  
  checkAndSetSession(){  
    if (this.isTokenPresent()) {
     let localUser = this.localStorage.getItem('user') ? JSON.parse(this.localStorage.getItem('user')) : null;
     this.user.next(localUser);
     this.refreshUser().toPromise();
   }
  }

  /**
   * Attenzione !!! questo metodo non verifica se il token è valido
   * @returns true se esiste un token JWT per l'utente
   */
  isTokenPresent() {
    let token = this.getToken();
    return token!=null;
  }

  parseErrorsValidation(error:HttpErrorResponse){ 
    return Object.values(error.error.errors);
  }

  setAgentTag(tag: string) {
    try {
      if (tag && tag != null) {
        console.log("tag " + tag);
        this.localStorage.setItem("tag", tag);
        console.log("localStorage tag " + localStorage.getItem("tag"));
      }
    } catch (e) {
      console.error("setAgentTag Exception");
      console.error(e);
    }
  }

  /**
   * @return tag agente Es. ?tag=pg10 -> pg10 o null se l'utente non ha acceduto da un link di un'agente
   */
  getAgentTag(): string {
    return this.localStorage.getItem("tag");
  }


}
