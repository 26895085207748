import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd,  Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  menuItems;
  newBreadcrumbs;

  env: any=environment;

  constructor(
    private router: Router, private activatedRoute: ActivatedRoute, public breadcrumbService: BreadcrumbService) {
  }


  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root)
      });
  }
  // tslint:disable-next-line:typedef
  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs = []) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (label) {
        breadcrumbs.push({label, url});
      }
      this.newBreadcrumbs = this.createBreadcrumbs(child, url, breadcrumbs);
      return this.newBreadcrumbs;
    }
  }
  createUrl(path): string {
    const siteUrl = environment.hostname;
    return siteUrl + path;
  }

  createBreadcrumbUrl(path: string, absoluteUrl = true): string {
    if (path && !path.startsWith('/')) {
      path = '/' + path;
    }
    if (absoluteUrl) {
      // url assoluto
      return environment.hostname + path;
    } else {
      // path relativo
      return path;
    }
  }

  goTo(path): void {
    this.router.navigateByUrl(path);
  }
}
