import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalData} from '../models/modal-data';
import {ErrorType} from '../shared/enum/errorType.enum';

@Injectable({
  providedIn: 'root'
})
export class ModalService<T> {
  private componentRef: ComponentRef<any> | undefined;
  private errors = new Subject<string>();
  private response = new Subject<any>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  // error
  public errorType = (errorType: string): void =>
    this.errors.next(errorType)

  public getErrors = () =>
    this.errors.asObservable()

  public getResponse = () =>
    this.response.asObservable()

  // apertura modale
  async open(component: Type<T>, modalData: ModalData): Promise<void> {
    if (this.componentRef) {
      return;
    }

    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory<T>(component)
      .create(this.injector);

    // atrtibuti da usare nel layout della modale errore
    this.componentRef.instance.html = modalData.html;
    this.componentRef.instance.title = modalData.title;
    this.componentRef.instance.text = modalData.text;
    this.componentRef.instance.width = modalData.width ? modalData.width : 'l';
    this.componentRef.instance.backdrop = modalData.backdrop != null ? modalData.backdrop : true;
    this.componentRef.instance.data = modalData.data;

    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as
      EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

  }

  // chisura modale
  async close(data?: any): Promise<void> {
    if (!this.componentRef) {
      return;
    }
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.componentRef = undefined;
    this.response.next(data);
  }

  // configurazione dei modalData della modale errore per le varie tipologie di errori
  getConfig(errorType): ModalData {
    let modalData: ModalData;
    console.log(errorType);
    const errorCode: ErrorType = this.getErrotCode(errorType);
    switch (errorCode) {
      case ErrorType.ShipmentType:
        return modalData = {
          title: 'Errore ShipmentType',
          text: 'Si è verificato un errore, si prega di riprovare più tardi oppure di contattarci ad assistenza@spediresubito.com',
          width: 'l',
          backdrop: true,
        };
      case ErrorType.Countries:
        return modalData = {
          title: 'Errore Countries',
          text: 'Si è verificato un errore, si prega di riprovare più tardi oppure di contattarci ad assistenza@spediresubito.com',
          width: 'm',
          backdrop: true,
        };
      case ErrorType.Internet:
        return modalData = {
          title: 'Errore Internet',
          text: 'Si è verificato un errore, si prega di riprovare più tardi oppure di contattarci ad assistenza@spediresubito.com',
          width: 'l',
          backdrop: true,
        };
      default:
        return modalData = {
          title: 'Errore default',
          text: 'Si è verificato un errore, si prega di riprovare più tardi oppure di contattarci ad assistenza@spediresubito.com',
          width: 's',
          backdrop: true,
        };
    }
  }

  // get error code by enum
  getErrotCode(errorType): ErrorType {
    if (errorType.includes('countries')) {
      return ErrorType.Countries;
    } else if (errorType.includes('shipment-type')) {
      return ErrorType.ShipmentType;
    } else if (errorType.includes('internetError')) {
      return ErrorType.Internet;
    } else {
      return ErrorType.Default;
    }
  }

}
