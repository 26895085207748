import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Breadcrumb } from '../models/breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  public breadcrumbs: Breadcrumb[];
  public hasBreadcrumbs = false;

  private _lastChildLabel: BehaviorSubject<string>;
  public lastChildLabel$: Observable<string>;

  constructor(protected http: HttpClient) {
    this._lastChildLabel = new BehaviorSubject('');
    this.lastChildLabel$ = this._lastChildLabel.asObservable();
  }

  changeLastChildLabel(value: string) {
    this._lastChildLabel.next(value);
  }

  loadBreadcrumbs(id: number, title: string): Observable<Breadcrumb[]> {
    return this.http.get(`${environment.apiUrl}/breadcrumbshort/${id}`).pipe(
      map(response => {
        let data = response['data'] as Breadcrumb[];
        if (data && data.length > 0) { 
          // DEBUG
          // console.log("loadBreadcrumbs id " + id + " title " + title);
          //data.forEach(function (b) {
          //   console.log('loadBreadcrumbs ' + b.label + ' ' + b.url);
          // });
          if (title !== data[data.length - 1]['label']) {
            data.push({ 'label': title }); 
            if (isDevMode()) console.log("loadBreadcrumbs label " + title);
          }
          this.breadcrumbs = data;
          this.hasBreadcrumbs = true;
        }
        return data;
      })
    )
  }
}
