// src/app/auth/auth-guard.service.ts
import { Injectable, isDevMode } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UrlHistoryService } from 'src/app/services/url-history.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, public urlHistoryService: UrlHistoryService) { }
  canActivate(): boolean {
    let prevUrl: string;
    try {
      prevUrl = this.router.getCurrentNavigation().previousNavigation?.finalUrl.toString();
    } catch (error) {
      prevUrl = '';
    } 
    if (!this.auth.isTokenPresent()) {
      if (isDevMode()) {
        console.log("canActivate AuthGuardService notValidAuth redirecting to 'accedi'");
        console.log('canActivate previousUrl ' + prevUrl);
        if (!prevUrl) {
          prevUrl = this.urlHistoryService.getPreviousUrl();
          console.log('canActivate previousUrl from urlHistoryService ' + prevUrl);
        }
      }
      this.router.navigate(['accedi'], { state: { previousUrl: prevUrl } });
      return false;
    }
    return true;
  }
}