import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbModule,
    LazyLoadImageModule,
    RouterModule
  ],
  exports: [
    FooterComponent
  ],
  providers: []
})
export class FooterModule { }