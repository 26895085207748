import { FlashMessageYaInterface } from "./FlashMessageYaInterface";
/**
 * Derived from 
 * https://github.com/moff/angular2-flash-messages
 * License MIT
 */
export class FlashMessageYa implements FlashMessageYaInterface {
    static nextId = 0;

    id: number = (FlashMessageYa.nextId++);
    text: string = 'default text';
    cssClass: string = '';
    closeOnClick: boolean = false;
    showCloseBtn: boolean = false;
    timer: number;

    constructor(text?: string, cssClass?: string, closeOnClick?: boolean, showCloseBtn?: boolean) {
        if (text) this.text = text;
        if (cssClass) this.cssClass = cssClass;
        if (closeOnClick) this.closeOnClick = closeOnClick;
        if (showCloseBtn) this.showCloseBtn = showCloseBtn;
    }
}