<app-header></app-header>

<main class="min-h-screen">
  <div class="fixed w-full">
    <div class="flex justify-center">
      <app-flash-messages-ya class="w-1/2"></app-flash-messages-ya>
    </div>
  </div>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>
<app-loader></app-loader>
