import { Injectable, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ViewportScroller, isPlatformBrowser } from '@angular/common';
import { fromEvent } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ScrollService implements OnDestroy {

  scrollY = new BehaviorSubject(0);
  scrollY$ = this.scrollY.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private vc: ViewportScroller) {
    if (isPlatformBrowser(platformId)) {
    fromEvent(window, 'scroll').pipe(untilDestroyed(this), debounceTime(50), distinctUntilChanged()).subscribe(
      () => {
        // console.log(vc.getScrollPosition())
        this.scrollY.next(vc.getScrollPosition()[1]);
      }
    )
    }
  }

  ngOnDestroy() { }
}
