import { Component, Input, OnInit } from '@angular/core';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  // @Input() id: string;
  @Input() width: string;
  @Input() backdrop: boolean;
  @Input() title: string;
  @Input() text: string;
  @Input() html: string;

  display = true;
  constructor(private modalService: ModalService<any>) {
  }

  ngOnInit(): void {
  }
  
  async close(): Promise<void> {
    this.display = false;
    setTimeout(async () => {
      await this.modalService.close();
    }, 300);
  }
}
