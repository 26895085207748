import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlashMessagesYaComponent } from './flash-messages-ya.component';
/**
 * Derived from 
 * https://github.com/moff/angular2-flash-messages
 * License MIT
 */
@NgModule({
    declarations: [
        FlashMessagesYaComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FlashMessagesYaComponent
    ],
    providers: []
})
export class FlashMessagesYaModule { }