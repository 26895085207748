import { Injectable } from '@angular/core';

@Injectable()
export class UrlHistoryService {

  /** Maximum length of url history */
  readonly MAX_HISTORY_LEN = 10;

  private history = [];

  constructor() { }
  /**
   * 
   * @param url url to be added to history
   */
  public addUrl(url: string): void {

    this.history.unshift(url);

    if (this.history.length > this.MAX_HISTORY_LEN) {
      this.history.length = this.MAX_HISTORY_LEN;
    }
  }

  /**
   * 
   * @returns url history (MAX_HISTORY_LEN)
   */
  public getHistory(): string[] {
    return this.history;
  }

  /**
   * 
   * @returns previous url
   */
  public getPreviousUrl(): string {
    return this.getUrl(1);
  }

  /**
   * 
   * @param back number of urls to go back
   * @returns previous 'back' url
   */
  public getUrl(back = 0): string {
    if (this.history.length > back) {
      return this.history[back];
    } else {
      return '/';
    }
  }
}
