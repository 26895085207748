import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, isDevMode } from "@angular/core";
import { LocalStorage } from "src/app/services/storage-service";
import { environment } from "src/environments/environment";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  env: any = environment;

  readonly chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor(
    private http:HttpClient,
    private localStorage: LocalStorage
  ) {
    
  }

  handleError(error: any) {
    try {

      console.log('GlobalErrorHandler error: ', error);

      if (isDevMode()) {
        console.log(error);
        return;
      }
    
      // Browser error interceptor code
      if (typeof window !== "undefined") {
    const userAgent = window.navigator.userAgent;
    const userUrl = window.location.href;

    const url = this.env.apiUrl + '/clienterror';

    if (userUrl && userUrl == 'about:blank') {
      return;
    }

    console.log('GlobalErrorHandler api url: ', url);

    let ls = {};
    for (let i = 0; i < this.localStorage.length; i++) {
        const key = this.localStorage.key(i); // Ottieni il nome della chiave
        let value = this.localStorage.getItem(key); // Ottieni il valore associato alla chiave
        if (key == 'shipment') {
            value = JSON.parse(value);
        }
        ls[key] = value;
    }

    let message = null;
    let stack = null;
    if (error.message) {
        message = error.message;
    }
    if (error.stack) {
        stack = error.stack;
    }

    const errorObj = { type: 'js', errorMessage: message, userUrl: userUrl, userAgent: userAgent, localStorage: ls, stacktrace: stack }

    console.log('GlobalErrorHandler send: ', errorObj);

    try {
      this.http.post(url, errorObj).subscribe(response => {
        console.log('GlobalErrorHandler send: ', response);
        console.log('GlobalErrorHandler send: done');
      }, (error: HttpErrorResponse) => {
        console.log('GlobalErrorHandler send error: ', error);
      });
    } catch(e) {
      console.log('GlobalErrorHandler send error: ', e);
    }

    // Richiesta di reload della pagina a seguito dell'aggiornamento del sito
    if (error.message && this.chunkFailedMessage.test(error.message)) {
      if (confirm("Sito aggiornato. Caricare la nuova versione?")) {
        window.location.reload();
      }
        }
      }
    } catch (e) {
      console.log('GlobalErrorHandler error: ', e);
    }

  }

}