import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private _isMenuOpened: BehaviorSubject<boolean>;
  public isMenuOpened$: Observable<boolean>;

  constructor() {
    this._isMenuOpened = new BehaviorSubject(false);
    this.isMenuOpened$ = this._isMenuOpened.asObservable();
  }

  open() {
    document.body.classList.add('overflow-hidden');
    this._isMenuOpened.next(true);
  }

  close() {
    document.body.classList.remove('overflow-hidden');
    this._isMenuOpened.next(false);
  }

  toggle() {
    this._isMenuOpened.value ? this.close() : this.open();
  }
}
