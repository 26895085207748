import { Injectable } from '@angular/core';
import { FlashMessageYa } from '../shared/components/flash-messages-ya/flashMessageYa';
import { FlashMessageYaInterface } from '../shared/components/flash-messages-ya/FlashMessageYaInterface';

/**
 * Derived from 
 * https://github.com/moff/angular2-flash-messages
 * License MIT
 */
@Injectable({
  providedIn: 'root'
})
export class FlashMessagesYaService {

  constructor() { }

  show: (text?: string, options?: Object) => void;
  grayOut: (value: boolean) => void;
}