import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule, provideClientHydration, } from '@angular/platform-browser'; // BrowserTransferStateModule
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import localeIt from '@angular/common/locales/it';

import { DEFAULT_TIMEOUT, GlobalHttpInterceptor } from './shared/interceptor/GlobalHttpInterceptor';
import { AuthGuardService } from './shared/guards/auth.guard';
import { HeaderModule } from './shared/components/header/header.module';
import { FlashMessagesYaModule } from './shared/components/flash-messages-ya/flash-message-ya.module';
import { FooterModule } from './shared/components/footer/footer.module';

import { LoaderService } from './services/loader.service';
import { LoaderModule } from './shared/components/loader/loader.module';

import { GoogleTagManagerModule } from './services/angular-google-tag-manager/angular-google-tag-manager.module';

import { environment } from 'src/environments/environment';
import { UrlHistoryService } from './services/url-history.service';
import { GlobalErrorHandler } from './shared/interceptor/GlobalErrorHandler';
import { provideRouter } from '@angular/router';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }), // Non pi� necessario
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    // BrowserTransferStateModule,
    HeaderModule,
    FlashMessagesYaModule,
    FooterModule,
    LoaderModule,
    GoogleTagManagerModule.forRoot({
      id: environment.google.gtm
    })
  ],
  providers: [
    LoaderService,
    AuthGuardService,
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 30000 },
    UrlHistoryService,
    provideHttpClient(withFetch()),
    provideRouter(routes),
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }